import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ["dropArea", "form", "input", "previewArea", "previewText", "submit"]

  declare dropAreaTarget: HTMLDivElement;
  declare formTarget: HTMLFormElement;
  declare inputTarget: HTMLInputElement;
  declare previewAreaTarget: HTMLDivElement;
  declare previewTextTarget: HTMLSpanElement;
  declare allowedExtensions: string[];
  declare maxSize: number;
  declare selectedFiles: File[];
  declare submitTarget: HTMLButtonElement;

  connect() {
    this.selectedFiles = [];
    this.allowedExtensions = JSON.parse(this.inputTarget.dataset.allowedExtensions) || [];
    this.maxSize = parseInt(this.inputTarget.dataset.maxSize) || 2000;

    this.dropAreaTarget.addEventListener('dragenter', this.highlight.bind(this));
    this.dropAreaTarget.addEventListener('dragover', this.highlight.bind(this));
    this.dropAreaTarget.addEventListener('dragleave', this.unhighlight.bind(this));
    this.dropAreaTarget.addEventListener('drop', this.handleDrop.bind(this));
    this.inputTarget.addEventListener('change', this.handleFileSelect.bind(this));
  }

  disconnect() {
    this.dropAreaTarget.removeEventListener('dragenter', this.highlight.bind(this));
    this.dropAreaTarget.removeEventListener('dragover', this.highlight.bind(this));
    this.dropAreaTarget.removeEventListener('dragleave', this.unhighlight.bind(this));
    this.dropAreaTarget.removeEventListener('drop', this.handleDrop.bind(this));
    this.inputTarget.removeEventListener('change', this.handleFileSelect.bind(this));
  }

  highlight(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropAreaTarget.classList.add('border-blue-500');
  }

  unhighlight(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropAreaTarget.classList.remove('border-blue-500');
  }

  handleDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFiles(files);
    }
    this.dropAreaTarget.classList.remove('border-blue-500');
  }

  handleFileSelect(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.handleFiles(files);
    }
  }

  handleFiles(files: FileList) {
    const isMultiple = this.inputTarget.hasAttribute('multiple');
    this.validateFiles(files);

    if (!isMultiple && this.selectedFiles.length > 1) {
      this.showAlert(`Multiple files cannot be selected. Please select only one.`, { type: 'error' });
      this.selectedFiles = [];
    }

    this.updateInputFiles();
    this.previewFiles();
  }

  validateFiles(files: FileList) {
    this.selectedFiles = [];

    Array.from(files).forEach(file => {
      if (!this.allowedExtensions.includes(file.type)) {
        this.showAlert(`${file.name} is not a valid type. Only CSV files are allowed.`, { type: 'error' });
        return;
      }

      if (file.size > this.maxSize) {
        this.showAlert(`File is too large: ${file.name} (${(file.size / 1024 / 1024).toFixed(2)} MB)`, { type: 'error' });
        return;
      }

      this.selectedFiles.push(file);
    });
  }

  updateInputFiles() {
    const dataTransfer = new DataTransfer();
    this.selectedFiles.forEach(file => dataTransfer.items.add(file));
    this.inputTarget.files = dataTransfer.files;
    if (this.inputTarget.files.length < 1) {
      this.submitTarget.setAttribute('disabled', '');
      this.submitTarget.classList.add('form-disabled');
    }
  }

  previewFiles() {
    this.previewAreaTarget.innerHTML = "";
    this.selectedFiles.forEach((file, index) => {
      const fileElement = document.createElement('div');
      fileElement.classList.add('p-2', 'border', 'border-gray-300', 'rounded', 'mb-2', 'bg-gray-100');
      fileElement.innerHTML = `
        <div class='flex gap-2'>
          <span class='truncate-container-text'>
            ${file.name}
          </span>
          <span  data-action="click->${this.identifier}#removeFile" data-index="${index}">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" class='w-6 h-6' viewBox="0 0 32 32" version="1.1">
              <title>remove</title>
              <path d="M11.188 4.781c6.188 0 11.219 5.031 11.219 11.219s-5.031 11.188-11.219 11.188-11.188-5-11.188-11.188 5-11.219 11.188-11.219zM11.25 17.625l3.563 3.594c0.438 0.438 1.156 0.438 1.594 0 0.406-0.406 0.406-1.125 0-1.563l-3.563-3.594 3.563-3.594c0.406-0.438 0.406-1.156 0-1.563-0.438-0.438-1.156-0.438-1.594 0l-3.563 3.594-3.563-3.594c-0.438-0.438-1.156-0.438-1.594 0-0.406 0.406-0.406 1.125 0 1.563l3.563 3.594-3.563 3.594c-0.406 0.438-0.406 1.156 0 1.563 0.438 0.438 1.156 0.438 1.594 0z"/>
            </svg>
          </span>
        </div>
      `;
      this.previewAreaTarget.appendChild(fileElement);
    });
    this.previewAreaTarget.setAttribute("title", this.previewAreaTarget.innerText);
  }

  removeFile(event) {
    const index = parseInt(event.currentTarget.dataset.index);
    this.selectedFiles.splice(index, 1);

    this.updateInputFiles();
    this.previewFiles();
  }
}
