import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['button'];

    checkSelected(event) {
        if (event.target.checked) {
            this.buttonTarget.classList.remove('form-disabled');
            this.buttonTarget.removeAttribute('disabled');
        } else {
            this.buttonTarget.classList.add('form-disabled');
            this.buttonTarget.setAttribute('disabled', '');
        }
    }
}
