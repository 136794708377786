import ApplicationController from './application_controller';
import zipValidators from './helpers/zip_validators';

export default class extends ApplicationController {
    static targets = ['country', 'postalCode'];

    static values = {
        userTriedSubmit: {
            type: Boolean,
            default: false,
        },
    };

    connect() {
        this.zipValidators = zipValidators;
        const context = this;
        if (this.hasPostalCodeTarget) {
            this.postalCodeTarget.addEventListener('form:submit', function (e) {
                context.userTriedSubmitValue = true;
                return context.checkZipCode(e);
            });
        }
    }

    checkZipCode() {
        var country = this.countryTarget.value;
        if (!country) {
            return;
        }

        var postalCodeFormat = JSON.parse(this.countryTarget.dataset.countriesData).filter((e) => e.alpha2 === country)[0].postal_code_format;
        var postalCode = this.postalCodeTarget.value.toUpperCase();

        if(!postalCodeFormat || !['US', 'CA', 'GB', 'AU'].includes(country)) {
            this.postalCodeTarget.required = false;
        } else {
            this.postalCodeTarget.required = true;
        }

        const regex = new RegExp(postalCodeFormat);
        const isValid = postalCode.length > 0 ? regex.test(postalCode) : true;

        if (!isValid) {
            this.sendEvent(this.postalCodeTarget, 'input:show-hint', {
                bubbles: true,
                detail: {
                    error: `This is not a valid zip code for ${this.countryTarget.selectedOptions[0].label}`,
                },
            });
        } else {
            this.sendEvent(this.postalCodeTarget, 'input:clear-hint');
        }
    }
}
