import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['azKey', 'connectNow', 'inviteOwner'];

    initialize() {
        if (this.data.get('provider') == 'amazon') {
            this.updateAmazonLink();
        } else {
            document.cookie = 'marketplace_id=' + ';path=/;expires=' + new Date(0) + ';';
        }
    }

    connect() {}

    get selectedAmazonRegion() {
        return this.azKeyTarget.value.split('|')[0];
    }

    get selectedMarketplaceId() {
        return this.azKeyTarget.value.split('|')[1];
    }

    updateAmazonLink() {
        let inviteOwnerHref = new URL(this.inviteOwnerTarget.href);
        if (this.selectedMarketplaceId) {
            document.cookie = `marketplace_id=${this.selectedMarketplaceId || ''};path=/`;
            inviteOwnerHref.searchParams.set('marketplace_id', this.selectedMarketplaceId);
        }
        this.connectNowTarget.href = JSON.parse(this.data.get('azUrls'))[this.selectedAmazonRegion];
        inviteOwnerHref.searchParams.set('region', this.selectedAmazonRegion);
        this.inviteOwnerTarget.href = inviteOwnerHref;
    }
}
