import ApplicationController from './application_controller';
import consumer from 'channels/consumer';

export default class extends ApplicationController {
    static targets = ['settings', 'connections', 'activity', 'apps', 'accounting', 'billing', 'entityInfo', 'entity'];

    static values = {
        entity: String,
    };

    connect() {
        super.connect();
        if (this.hasEntityTarget) {
            this.subscription = consumer.subscriptions.create(
                {
                    channel: 'EntityActionItemChannel',
                    id: this.entityTarget.innerText,
                },
                {
                    received: this._resetData.bind(this),
                }
            );
        }
    }

    _resetData(badges) {
        ['settings', 'connections', 'activity', 'apps', 'accounting', 'billing', 'entityInfo'].forEach((badge) => {
            if (this[`has${badge[0].toUpperCase()}${badge.substring(1)}Target`]) {
                this[`${badge}Targets`].forEach((element) => {
                    if (!badges[badge]) {
                        element.classList.add('hidden');
                        return;
                    }
                    element.classList.remove('hidden');
                    element.innerHTML = badges[badge];
                });
            }
        });
    }

    reload() {
        const ctx = this;
        this.ajax({
            url: '/entities/' + this.entityTarget.innerText + '/badges',
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                ctx._resetData(data);
            },
        });
    }

    disconnect() {
        this.subscription && this.subscription.unsubscribe();
        super.disconnect();
    }
}
