import ApplicationController from './application_controller';
import { createPopper } from '@popperjs/core';

export default class extends ApplicationController {
    static targets = ['tooltip'];

    static values = {
        placement: { type: String, default: 'right' },
        offset: { type: Array, default: [0, 8] },
        class: { type: String, default: '' },
    };

    initialize() {
        if (!this.hasTooltipTarget) {
            this.element.insertAdjacentHTML('beforeend', this.getTooltipTemplate(this.data.get('tooltip')));
        }
    }

    connect() {
        if (this.hasTooltipTarget) {
            this.setPopperInstance();
        }
    }

    show() {
        if (this.hasTooltipTarget) {
            this.tooltipTarget.setAttribute('data-show', '');
        }

        // We need to tell Popper to update the tooltip position
        // after we show the tooltip, otherwise it will be incorrect
        this.popperInstance?.update();
    }

    hide() {
        if (this.hasTooltipTarget) {
            this.tooltipTarget.removeAttribute('data-show');
        }
    }

    setPopperInstance() {
        this.popperInstance = createPopper(this.element, this.tooltipTarget, {
            placement: this.placementValue,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: this.offsetValue,
                    },
                },
            ],
        });
    }

    getTooltipTemplate(tooltipValue) {
        if (tooltipValue) {
            let popperWide = this.element.dataset.popperWide == 'true' ? 'popper-wide' : '';
            return (
                '<div class="tooltip ' +
                this.classValue +
                ' ' +
                popperWide +
                '" role="tooltip" data-popper-target="tooltip">' +
                tooltipValue +
                '<div class="arrow" data-popper-arrow></div>' +
                '</div>'
            );
        } else {
            return '';
        }
    }

    // Destroy the Popper instance
    disconnect() {
        if (this.popperInstance) {
            this.popperInstance?.destroy();
        }
    }
}
