import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['frame'];

    static values = {
        url: String,
    };

    connect() {
        super.connect();
    }

    load() {
        if (!this.frameTarget.src) {
            this.frameTarget.src = this.urlValue;
        }
    }
}
