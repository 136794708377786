import ModalController from '../modal_controller';

export default class extends ModalController {
    static targets = ModalController.targets.concat(['frame', 'navigator']);

    static values = {
        showLoader: {
            type: Boolean,
            default: false,
        },
    };

    initialize() {
        document.addEventListener('turbo-popup:open', this.open);
    }

    connect(e) {
        super.connect(e);
        this.element['popupController'] = this;
        const loadedURL = new URL(window.location.href);
        const requestedPopUrl = loadedURL.searchParams.get('pop');
        if (requestedPopUrl) {
            loadedURL.searchParams.delete('pop');
            window.history.replaceState({}, '', loadedURL.toString());
            this.openPopup(requestedPopUrl);
        }
    }

    openPopup(url) {
        this.navigatorTarget.href = url;
        this.navigatorTarget.click();
    }

    removeLoader() {
        if (this.showLoaderValue) {
            this.frameTarget.classList.remove('min-h-32');
            this.frameTarget.classList.remove('min-w-32');
        }
        this.showLoaderValue = false;
    }

    open(e) {
        if (e.params['showLoadingIcon']) {
            this.showLoaderValue = true;
            this.frameTarget.classList.add('min-h-32');
            this.frameTarget.classList.add('min-w-32');
        }
        if (e && e.target.dataset.escapable == 'false') {
            this.containerTarget.dataset.action = '';
        } else {
            this.containerTarget.dataset.action =
                'click->controls--turbo-popup#closeBackground keyup@window->controls--turbo-popup#closeWithKeyboard';
        }
        this.prevTitle = document.title;
        this.prevUrl = window.location.href;
        super.open(e);
    }

    setRefreshOnNextClose({ params: { refreshOnClose } }) {
        this.data.set('refreshOnNextClose', refreshOnClose);
    }

    checkChangeTrackerChanges() {
        var eventFlag = true;
        if (document.body.controller) {
            if (this.element.querySelector('[data-controller*="change-tracker"]')) {
                this.element.querySelectorAll('[data-controller*="change-tracker"]').forEach((el) => {
                    let event = document.body.controller.sendEvent(el, 'change-tracker:check-changes', {
                        bubbles: true,
                        cancelable: true,
                    });
                    if (!event) {
                        eventFlag = event;
                        return;
                    }
                });
            }
        }
        return eventFlag;
    }

    closeWithKeyboard(e) {
        if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
            let event = this.checkChangeTrackerChanges(e);
            if (!event) {
                e.stopImmediatePropagation();
                return;
            }
        }
        super.closeWithKeyboard(e);
        this.removeLoader();
    }

    closeBackground(e) {
        if (e.target === this.containerTarget) {
            let event = this.checkChangeTrackerChanges(e);
            if (!event) {
                e.stopImmediatePropagation();
                return;
            }
        }
        super.closeBackground(e);
        this.removeLoader();
    }

    close(e) {
        let event = this.checkChangeTrackerChanges(e);
        if (!event) {
            e.stopImmediatePropagation();
            return;
        }
        let refresh;
        if (e) {
            refresh = e.target.dataset.refresh;
            if (this.data.get('refreshOnNextClose') == 'true') {
                this.data.set('refreshOnNextClose', false);
                refresh = 'true';
            }
            if (refresh == 'true') {
                super.close(e);
                let redirectUrl = this.prevUrl;
                if (e.target.dataset.redirectUrl) {
                    redirectUrl = e.target.dataset.redirectUrl;
                }
                window.Turbo.visit(redirectUrl);
            } else {
                if (this.prevUrl) {
                    window.history.replaceState({}, null, this.prevUrl);
                }
                super.close(e);
                this.frameTarget.innerHTML = '';
            }
        } else {
            super.close(e);
        }
        this.removeLoader();
        if (this.prevTitle) document.title = this.prevTitle;
    }
}
