import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['control'];

    validateUserRole() {
        let isValid = true;
        this.sendEvent(this.controlTarget, 'input:clear-hint');
        if (this.controlTarget.dataset.currentRole != this.controlTarget.value) {
            if (this.controlTarget.value == 'Admin') {
                if (
                    this.controlTarget.dataset.ownersCount == 1 ||
                    this.controlTarget.dataset.currentUserIsOwner == 'false'
                ) {
                    isValid = false;
                    this.showError('1 owner is required');
                }
            } else if (this.controlTarget.value == 'Owner') {
                if (this.controlTarget.dataset.currentUserIsOwner == 'true') {
                    this.showWarning('By performing this action, you will be no longer be the Owner of this Entity');
                } else {
                    isValid = false;
                    this.showError('Only 1 owner is allowed');
                }
            }
        }
        return isValid;
    }


    showError(msg) {
        this.sendEvent(this.controlTarget, 'input:show-hint', {
            bubbles: true,
            detail: {
                error: msg,
            },
        });
    }

    showWarning(msg) {
        this.sendEvent(this.controlTarget, 'input:show-hint', {
            bubbles: true,
            detail: {
                warning: msg,
            },
        });
    }
}
