import { createElement } from '@utils';
import clickOutside from 'click-outside';
import { PopupOption } from './types/';
export class Popup {
    private popupContainer: HTMLElement;
    private options: PopupOption;
    private actions_x_align = {
        left: 'justify-start',
        center: 'justify-center',
        right: 'justify-end',
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private unbindClickOutside: any;

    constructor(options: PopupOption) {
        this.options = options;
        this.createContainer();
    }

    private onClickOutside = (e) => {
        console.log(e);
        this.options.onClose?.(e);
        this.closeContainer();
    };

    private createContainer() {
        this.popupContainer = createElement(
            '<div class="absolute top-0 w-screen h-screen overflow-y-auto flex items-center justify-center z-40" style="background-color: rgba(0,0,0,0.533);"></div>'
        );

        const innerContainer = createElement(
            '<div class="flex flex-col bg-white rounded-lg w-max max-h-screen"></div>'
        );

        const contentContainer = createElement(
            '<div class="z-50 flex flex-col p-8 items-center justify-center"></div>'
        );

        const headerContainer = createElement('<div class="w-full flex items-center justify-between pb-6"></div>');

        const titleEle = createElement(`<span class="text-2xl">
                                                ${this.options.title}
                                            </span>`);

        const closeEle = createElement(`
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        `);

        closeEle.addEventListener('click', (e: Event) => {
            this.options.onClose?.(e);
            this.closeContainer();
        });

        headerContainer.appendChild(titleEle);
        headerContainer.appendChild(closeEle);

        const contentBody = createElement(`<div id="popupContentContainer" class="max-h-screen flex flex-col">
                                                ${this.options.content()}
                                            </div>`);

        contentContainer.appendChild(headerContainer);
        contentContainer.appendChild(contentBody);

        innerContainer.appendChild(contentContainer);
        this.popupContainer.appendChild(innerContainer);
        innerContainer.appendChild(this.createActions());

        document.querySelector('body').appendChild(this.popupContainer);

        setTimeout(() => (this.unbindClickOutside = clickOutside(innerContainer, this.onClickOutside)), 0);
    }

    private createActions() {
        const actionsContainer = createElement(
            `<div class="px-8 py-4 flex ${
                this.actions_x_align[this.options.actions_x_align] || 'justify-center'
            } gap-6"></div>`
        );

        let actionElement;
        this.options.actions.forEach((action) => {
            if (action.type == 'text') {
                actionElement = createElement(`<button class="focus:outline-none">${action.label}</button>`);
            } else if (action.type == 'primary') {
                actionElement = createElement(`<button class="!rounded-lg btn-primary" >${action.label}</button>`);
            } else if (action.type == 'danger') {
                actionElement = createElement(`<button class="!rounded-lg btn-danger" >${action.label}</button>`);
            } else if (action.type == 'lite') {
                actionElement = createElement(`<button class="!rounded-lg btn-primary-lite" >${action.label}</button>`);
            }
            actionElement.addEventListener('click', (e: Event) => {
                action.onClick(e);
            });

            actionsContainer.appendChild(actionElement);
        });

        return actionsContainer;
    }

    closeContainer() {
        if (this.popupContainer) {
            this.popupContainer.parentElement.removeChild(this.popupContainer);
        }
        this.unbindClickOutside?.();
    }
}
