import ApplicationController from './application_controller';
import { stringify } from 'querystring';

export default class extends ApplicationController {
	static targets = ['viewAsAdminInput'];

	userViewSwitch() {
		var context = this;

		this.ajax({
			url: '/users/update_session_for_view_as_admin',
			type: 'POST',
			dataType: 'json',
			data: stringify({
				view_as_admin: context.viewAsAdminInputTarget.checked,
			}),
			success: function (data) {
				window.location.reload();
			},
		});
	}
}
