import ApplicationController from './application_controller';
import { enter, leave } from 'el-transition';

export default class extends ApplicationController {
    static targets = ['header', 'content', 'switch'];

    connect() {}

    // call the enter and leave functions
    toggle(e) {
        e.preventDefault();
        if (this.contentTarget.classList.contains('hidden')) {
            this.element.classList.add('open');
            enter(this.contentTarget);
        } else {
            this.element.classList.remove('open');
            leave(this.contentTarget);
        }
    }
}
