import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['addUser', 'selectEntities', 'select', 'allEntities', 'entityCbx', 'entitiesList', 'submit'];

    connect() {
        if (this.hasSelectEntitiesTarget) {
            this.changeText();
        }
    }

    changeText() {
        if (['admin', 'primary_admin'].includes(this.selectTarget.value)) {
            this.element.action = this.element.dataset.adminUrl;
            this.element.dataset.turboFrame = '_top';
            this.addUserTarget.classList.remove('hidden');
            this.selectEntitiesTarget.classList.add('hidden');
        } else {
            this.element.dataset.turboFrame = 'turbo-popup';
            this.element.action = this.element.dataset.staffUrl;
            this.addUserTarget.classList.add('hidden');
            this.selectEntitiesTarget.classList.remove('hidden');
        }
    }

    entitySelection() {
        if (this.allEntitiesTarget.checked) {
            this.entityCbxTargets.forEach((element) => {
                element.checked = true;
            });
        } else {
            this.entityCbxTargets.forEach((element) => {
                element.checked = false;
            });
        }
        this.updateSelectionArray();
    }

    entitySelectionChanged(e) {
        if (!e.target.checked) {
            this.allEntitiesTarget.checked = false;
        }
        this.updateSelectionArray();
    }

    updateSelectionArray() {
        let entities = [];
        this.entityCbxTargets.forEach((element) => {
            if (element.checked) {
                entities.push(parseInt(element.name));
            }
        });
        if (this.allEntitiesTarget.checked || entities.length > 0) {
            this.submitTarget.classList.remove('form-disabled');
            this.submitTarget.disabled = false;
        } else {
            this.submitTarget.classList.add('form-disabled');
            this.submitTarget.disabled = true;
        }
        this.entitiesListTarget.value = entities;
    }
}
