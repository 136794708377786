import DropdownController from './dropdown_controller';

export default class extends DropdownController {
    static targets = ['menu', 'button', 'buttonText'];

    connect() {
        super.connect();
    }

    click(e) {
        this.buttonTextTarget.innerText = e.currentTarget.innerText;
        this.toggleMenu();
    }
}
