import { Grid } from '@components';
import { PageOptions, SortOptions } from '@components/shared';
import { stringifyParams } from '@utils';
import ApplicationController from './application_controller';
import consumer from '../channels/consumer';

export default class extends ApplicationController {
    static targets = [
        'gridContainer',
        'connectionFilter',
        'statusFilter',
        'startDateFilter',
        'endDateFilter', 
    ];

    declare gridContainerTarget: HTMLElement;
    declare connectionFilterTarget: HTMLInputElement;
    declare statusFilterTarget: HTMLInputElement;
    declare startDateFilterTarget: HTMLInputElement;
    declare endDateFilterTarget: HTMLInputElement;

    static values = {
        confirmActionFormId: String,
    };

    private grid: Grid<ReconciliationReport>;
    private parmsObj: ParamsObj = {};
    private disabled: boolean;
    private reconciliationReportsSubscription: any;

    connect(): void {
        this.reconciliationReportsSubscription = consumer.subscriptions.create(
            {
                channel: 'ReconciliationReportsChannel',
                user_id: this.element.dataset.userId,
            },
            {
                received: this.handleRowUpdates,
            }
        );

        let reconciliationReportsParams = new URLSearchParams(window.location.search);
        this.parmsObj.page = {
            size: parseInt(reconciliationReportsParams.get('page[size]')) || 30,
            number: parseInt(reconciliationReportsParams.get('page[number]')) || 1
        };

        this.createGrid();
        // Apply default filters before reloading the data
        this.applyFilters({ detail: {} } as CustomEvent, true);
    }

    disconnect() {
        this.reconciliationReportsSubscription && this.reconciliationReportsSubscription.unsubscribe();
        super.disconnect();
    }

    private handleRowUpdates = (update: ReconciliationReport) => {
        this.grid.updateRowData(update);
    };

    private createGrid() {
        this.grid = new Grid<ReconciliationReport>({
            key: 'id',
            allowSelection: false,
            showEmptyState: true,
            noRecordsTemplate: this.noRecordsTemplate,
            container: this.gridContainerTarget,
            columns: [
                {
                    type: 'checkbox',
                    hideColumn: true,
                },
                {
                    headerTitle: 'Sales Day',
                    field: 'sale_day',
                    width: '125px',
                    sortable: true,
                    cellTemplate: (reconciliationReport) => {
                        return `
                            <span class="ow-anywhere min-w-24 pr-1"> 
                                ${reconciliationReport.sale_day}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Payments Expected',
                    field: 'payments_expected',
                    sortable: true,
                    cellTemplate: (reconciliationReport) => {
                        return `
                            <span class="ow-anywhere min-w-24 pr-1"> 
                               ${this.formatToTwoDecimals(reconciliationReport.payments_expected, reconciliationReport.currency_code)}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Payments Received',
                    field: 'payments_received',
                    sortable: true,
                    cellTemplate: (reconciliationReport) => {
                        return `
                            <span class="ow-anywhere min-w-24 pr-1"> 
                                ${this.formatToTwoDecimals(reconciliationReport.payments_received, reconciliationReport.currency_code)}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Pending Payout',
                    cellTemplate: (reconciliationReport) => {
                        return `
                            <span class="ow-anywhere min-w-24"> 
                                ${this.formatToTwoDecimals(reconciliationReport.pending_payout, reconciliationReport.currency_code)}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Status',
                    field: 'status',
                    width: '128px',
                    sortable: true,
                    cellTemplate: (reconciliationReport) => {
                        return `
                            <span class="ow-anywhere min-w-20"> 
                                ${reconciliationReport.status}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Entries',
                    sortable: false,
                    width: '96px',
                    onCellClicked: (rowData: ReconciliationReport) => {
                        (window as any).openPopup(`/reconciliation_reports/${this.element.dataset.orgId}/associated_entries_view?id=${rowData.id}`);
                    },
                    cellTemplate: () => {
                        return `
                            <span class="cursor-pointer ow-anywhere min-w-24 text-bkblue">
                                View
                            </span>
                        `;
                    }
                }
            ],
            pageOptions: {
                number: this.parmsObj.page.number,
                size: this.parmsObj.page.size,
            },
            onSort: (sort: SortOptions) => this.sort(sort),
            onPageChange: (page: PageOptions) => this.page(page)
        });
    }

    formatToTwoDecimals(value: number, currency: string): string {
        if (typeof value !== 'number') {
            value = parseFloat(value);
            if (isNaN(value)) {
              return '0.00';
            }
        }
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: currency }).format(value);
    }

    private noRecordsTemplate = () => {
        let message = 'No records to show.';
        return `<div class="h-96 flex justify-center items-center text-gray-600 px-4">
        <div class="text-center"> ${message} </div>`;
    };

    previous(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) - 1;
        this.reload();
    }

    next(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) + 1;
        this.reload();
    }

    disableButtons(): void {
        this.disabled = true;
    }

    enableButtons(): void {
        this.disabled = false;
    }

    reload(): void {
        let url = '';
        let currentPageSize = this.parmsObj.page.size
        const query = stringifyParams(this.parmsObj);
        if (query.length) {
            url = url + '?' + query;
            window.history.pushState({}, '', url);
        }
        this.ajax({
            url,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                this.grid.pageOptions.size = currentPageSize;
                this.grid.pageOptions.number = data.meta.pagination.current;
                this.grid.totalResultsCount = data.meta.pagination.records;
                this.grid.data = data.data;
            },
        });
    }

    pageSize(e: Event): void {
        this.parmsObj.page.number = 1;
        e.preventDefault();
        this.reload();
    }

    private sort(sort: SortOptions): void {
        this.parmsObj.sort = sort.direction == 'asc' ? sort.column : '-' + sort.column
        this.reload();
    }

    private page(page: PageOptions): void {
        this.parmsObj.page = {
            number: page.number,
            size: page.size
        };
        this.reload();
    }

    resetSelected(): void {
        this.grid.resetSelection();
    }

    applyFilters(e: CustomEvent, initialLoad): void {
        // checking if the event is triggered by the page load or not
        if (!initialLoad) {
            this.parmsObj.page.number = 1;
        }
        delete this.parmsObj.filter;

        // the page gets reloaded when something is changed in other tabs which removing id from params for associated entries popup. so preserving the id
        var params = new URLSearchParams(window.location.search);
        if(params.get('id') !== null) {
            this.parmsObj.id = params.get('id');
        } else {
            this.parmsObj.id = '0';
        }

        
        // connection filter starts here
        let filter_value = this.connectionFilterTarget.getAttribute('data-search-select-value')
        this.parmsObj.filter ||= {}
        this.parmsObj.filter['provider_authorization_id_eq'] = filter_value;
        // connection filter ends here

        // status filter starts here
        filter_value = this.statusFilterTarget.getAttribute('data-search-select-value')
        this.parmsObj.filter ||= {}
        this.parmsObj.filter['reconciled_eq'] = filter_value;
        // status filter ends here

        // date range filter starts here
        filter_value = this.startDateFilterTarget.value;
        if (filter_value != '') {
            this.parmsObj.filter ||= {}
            this.parmsObj.filter['sale_day_gteq'] = filter_value;
        }

        filter_value = this.endDateFilterTarget.value;
        if (filter_value != '') {
            this.parmsObj.filter ||= {}
            this.parmsObj.filter['sale_day_lteq'] = filter_value;
        }
        // date range filter ends here

        this.resetSelected();
        this.reload();
    }
}

interface ParamsPageObj {
    number?: number
    size?: number
}

interface ParamsObj {
    id?: string;
    filter?: object;
    status?: string;
    sort?: string;
    page?: ParamsPageObj;
    notifications_status?: string;
}

interface ReconciliationReport {
    id: number;
    sale_day: Date;
    data: JSON;
    payments_expected: number;
    payments_received: number;
    pending_payout: number;
    currency_code: string;
    status: string;
}
