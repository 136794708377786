// bg-secondary
import ApplicationController from './application_controller';
import { stringify } from 'querystring';

export default class extends ApplicationController {
    static targets = ['email', 'errorLabel', 'newUserMessage'];

    static values = {
        url: {
            type: String,
            default: '/find_company_user',
        },
    };

    checkEmail() {
        if (!this.emailTarget.isValid) {
            return;
        }
        var email = this.emailTarget.value;
        var context = this;
        if (email.trim().length) {
            this.ajax({
                url: this.urlValue,
                type: 'POST',
                dataType: 'json',
                data: stringify({ email: email }),
                success: (data) => {
                    context.errorLabelTarget.innerHTML = data.label;
                    context.errorLabelTarget.classList.remove('bg-red-600');
                    context.errorLabelTarget.classList.remove('bg-bkgreen');
                    context.errorLabelTarget.classList.remove('bg-bkyellow');
                    context.errorLabelTarget.classList.add(data.color_code);
                    context.errorLabelTarget.classList.remove('hidden');
                    if (data.new_user) {
                        this.newUserMessageTarget.classList.remove('hidden');
                    } else {
                        this.newUserMessageTarget.classList.add('hidden');
                    }
                    if (data.color_code == 'bg-red-600') {
                        context.sendEvent(context.emailTarget, 'input:set-control-valid', {
                            bubbles: true,
                            detail: { valid: false },
                        });
                    } else {
                        context.sendEvent(context.emailTarget, 'input:set-control-valid', {
                            bubbles: true,
                            detail: { valid: true },
                        });
                    }
                    context.sendEvent(context.element, 'form:check-required-fields');
                },
            });
        }
    }
}
