import { Dropdown } from '@components';
import { DropdownItem } from '@components/dropdown';
import { PageOptions, SortOptions } from '@components/shared';
import { createElement } from '@utils';
import { GridAction, GridColumn, GridOptions, GridRow } from './types';

export class Grid<T> {
    private columns: GridColumn<T>[] = [];
    private container: HTMLElement;
    private _data: T[] = [];
    private pageSizes = [30, 50, 100, 200];
    private allowSelection = true;
    private key = '';
    pageOptions: PageOptions = { number: 1, size: this.pageSizes[0] };
    private _totalResultsCount = 0;
    private _totalResultsPerTemplate = 0;
    private _entityCreatedDate = '';
    showEmptyState = false;
    noRecordsTemplate?: () => string;
    private pageSizeElement: HTMLElement;
    private actions: GridAction<T>[] = [];
    onSort: (data: SortOptions) => void;
    onPageChange: (data: PageOptions) => void;

    private _defaultConfig: GridOptions<T> = {
        data: this._data,
        columns: this.columns,
        allowSelection: this.allowSelection,
        key: this.key,
        noRecordsTemplate: this.noRecordsTemplate,
        container: '',
        pageSizes: this.pageSizes,
        pageOptions: this.pageOptions,
        totalItemsCount: this._totalResultsCount,
        totalResultsPerTemplate: this._totalResultsPerTemplate,
        entityCreatedDate: this._entityCreatedDate,
        showEmptyState: this.showEmptyState,
        actions: this.actions,
    };

    rowsContainer: HTMLElement;
    selectAllBox: HTMLInputElement;
    pageTextElement: HTMLElement;
    prevButtonElement: HTMLElement;
    nextButtonElement: HTMLElement;
    private nextClickFn: (e: Event) => void;
    private prevClickFn: (e: Event) => void;
    rowStates: GridRow<T>[];
    selectAllChecked: boolean;
    isRowDisabled: (data: T) => boolean;
    onSelectionChange: (selected: GridRow<T>[]) => void;

    constructor(options: GridOptions<T>) {
        options = Object.assign(this._defaultConfig, options);
        this._data = options.data;
        this.columns = options.columns;
        this.pageSizes = options.pageSizes;
        this.pageOptions = options.pageOptions;
        this.noRecordsTemplate = options.noRecordsTemplate;
        this.onSort = options.onSort;
        this.onPageChange = options.onPageChange;
        this.isRowDisabled = options.isRowDisabled;
        this.onSelectionChange = options.onSelectionChange;
        this.key = options.key;

        if (typeof options.container == 'string') {
            this.container = document.querySelector<HTMLElement>(options.container);
        } else {
            this.container = options.container as HTMLElement;
        }

        if (!this.container) {
            throw new Error('Container has to be specified');
        }

        this.renderContainer();

        this.renderRows();
    }

    private addSort(element, column) {
        if (column.sortable) {
            const sortUp = createElement(
                '<div class=" opacity-10 sort-chevron-up text-gray-500 cursor-pointer"></div>'
            );
            const sortDown = createElement(
                '<div class=" opacity-10 sort-chevron-down text-gray-500 cursor-pointer"></div>'
            );

            if (typeof this.onSort === 'function') {
                sortUp.addEventListener('click', () => {
                    this.removeHighlight();
                    this.onSort({
                        column: column.field,
                        direction: 'asc',
                    });
                    sortUp.className = 'opacity-100 sort-chevron-up text-gray-500 cursor-pointer';
                    sortDown.className = ' opacity-10 sort-chevron-down text-gray-500 cursor-pointer';
                });
                sortDown.addEventListener('click', () => {
                    this.removeHighlight();
                    this.onSort({
                        column: column.field,
                        direction: 'desc',
                    });
                    sortDown.className = 'opacity-100 sort-chevron-down text-gray-500 cursor-pointer';
                    sortUp.className = 'opacity-10 sort-chevron-up text-gray-500 cursor-pointer';
                });
            }

            const container = createElement(`
                <div class="flex flex-col sort-chevrons">
                </div>`);
            container.appendChild(sortUp);
            container.appendChild(sortDown);

            element.appendChild(container);
        }
    }

    private removeHighlight() {
        const up = Array.prototype.slice.call(document.getElementsByClassName('sort-chevron-up'));
        const down = Array.prototype.slice.call(document.getElementsByClassName('sort-chevron-down'));
        up.forEach((element: HTMLElement) => {
            element.className = ' opacity-10 sort-chevron-up text-gray-500 cursor-pointer';
        });
        down.forEach((element: HTMLElement) => {
            element.className = ' opacity-10 sort-chevron-down text-gray-500 cursor-pointer';
        });
    }

    get data(): T[] {
        return this._data;
    }

    set data(value: T[]) {
        this._data = value;
        this.showEmptyState = true;
        this.renderRows();
        this.rowsContainer.scrollTop = 0;
        this.renderPaging();
    }

    get totalResultsCount(): number {
        return this._totalResultsCount;
    }

    set totalResultsCount(value: number) {
        this._totalResultsCount = value;
        this.renderPaging();
    }

    get totalResultsPerTemplate(): number {
        return this._totalResultsPerTemplate;
    }

    get entityCreatedDate(): string {
        return this._entityCreatedDate;
    }

    set entityCreatedDate(value: string) {
        this._entityCreatedDate = value;
    }

    set totalResultsPerTemplate(value: number) {
        this._totalResultsPerTemplate = value;
    }

    private generateHeader(container: HTMLElement) {
        const titleContainer = container.querySelector('[bk-column-headers]');

        this.columns.forEach((column) => {
            const add_class = column.hideColumn ? 'hidden' : '';
            if (column.type === 'checkbox') {
                const ele = createElement(
                    `<div class="px-4 pr-6 py-2 sm:py-4 mr-auto sm:mr-0 ${add_class}">
                        <input type="checkbox" class="cursor-pointer">
                    </div>`
                );
                this.selectAllBox = ele.querySelector('input') as HTMLInputElement;
                this.selectAllBox.addEventListener('click', (e: Event) => {
                    this.toggleSelectAll((e.currentTarget as HTMLInputElement).checked);
                });
                titleContainer.appendChild(ele);
            } else {
                const ele = createElement(
                    `<div class="flex flex-row px-2 py-2 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase items-center tracking-wider ${add_class}">
                        <span class="pr-2">${column.headerTitle || ''}</span>
                    </div>`
                );
                if (column.width) {
                    ele.style.width = column.width;
                } else {
                    if (column.type == 'actions') {
                        ele.style.width = '96px';
                    } else {
                        ele.classList.add('flex-1');
                    }
                }
                this.addSort(ele, column);
                titleContainer.appendChild(ele);
            }
        });
    }

    private toggleSelectAll(checked: boolean) {
        for (const rowState of this.rowStates) {
            if (!rowState.disabled) {
                rowState.selected = checked;
            }
        }
        this.handleSelectAll();
        this.emitSelectionChange();
    }

    private createContainer() {
        const container = createElement<HTMLDivElement>(`
            <div class="flex flex-col mt-2" data-busy-content="">
                <div class="align-middle min-w-full overflow-x-auto border sm:rounded-lg">
                    <div class="min-w-full divide-y divide-gray-200">
                        <div class="hidden sm:flex bg-gray-50 " data-grid-target="" bk-column-headers>
                        </div>
                        <div class="dd-scroll-container bg-white flex flex-col fix-screen-height overflow-y-auto relative" bk-data-rows>
                            
                        </div>
                    </div>
                    <!-- Pagination -->
                    <nav class="bg-white px-4 py-3 flex flex-col sm:flex-row justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                        <div class="pb-5 sm:pb-0 justify-between flex items-center">
                            <div class="text-sm text-gray-700 mr-1">
                                <div data-controller="dropdown" class="relative">
                                    <div class="flex items-center">
                                        <label for="page-menu">
                                        Page size:
                                        </label>    
                                        <button type="button" class="ml-0 max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none
                                                lg:p-2 lg:rounded-md lg:hover:bg-gray-50" id="page-menu" aria-expanded="false" aria-haspopup="true" data-target="dropdown.button" data-action="click->dropdown#toggleMenu">
                                            <span class="block ml-2 text-gray-700" bk-current-page-size></span>
                                            <!-- Heroicon name: solid/chevron-down -->
                                            <svg class="flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div bk-page-size-options class="hidden origin-top-right absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="page-menu" data-dropdown-target="menu" data-transition-enter="transition ease-out duration-100" data-transition-enter-start="transform opacity-0 scale-95" data-transition-enter-end="transform opacity-100 scale-100" data-transition-leave="transition ease-in duration-75" data-transition-leave-start="transform opacity-100 scale-100" data-transition-leave-end="transform opacity-0 scale-95"
                                        data-action="click->dropdown#closeMenu"
                                    >

                                    </div>
                                </div>
                            </div>
                            <p class="text-sm text-gray-700" bk-page-text>
                                Showing
                                    <span class="font-medium">0</span>
                                results
                            </p>
                        </div>
                        <div class="flex-1 flex sm:justify-end sm:pr-16">
                            <a class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm
                                    font-medium rounded-md text-gray-700 bg-white" bk-prev> 
                                Previous
                            </a>
    
                            <a class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm
                                    font-medium rounded-md text-gray-700 bg-white" bk-next> 
                                Next 
                            </a>
                        </div>
                    </nav>
                </div>
            </div>`);

        this.generateHeader(container);

        this.pageSizes.map((size) => {
            const ele = createElement(`
                    <a href="" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"role="menuitem">${size}</a>
                `);
            ele.addEventListener('click', (e) => {
                this.pageSizeChanged(size);
                e.preventDefault();
            });
            container.querySelector('[bk-page-size-options]').appendChild(ele);
        });

        this.pageSizeElement = container.querySelector('[bk-current-page-size]');
        this.pageTextElement = container.querySelector('[bk-page-text]');
        this.prevButtonElement = container.querySelector('[bk-prev]');
        this.nextButtonElement = container.querySelector('[bk-next]');

        this.nextClickFn = this.nextClick.bind(this);
        this.prevClickFn = this.prevClick.bind(this);

        this.renderPaging();

        return container;
    }

    private renderPaging() {
        this.renderPageSize();
        this.renderPageText();
        this.renderPageButtons();
    }

    private renderPageText() {
        let text: string;
        if (this._totalResultsCount <= 0) {
            text = ` 
                Showing
                    <span class="font-medium">0</span>
                results
            `;
        } else {
            const { size, number } = this.pageOptions;
            const pageStart = size * (number - 1) + 1;
            const pageEnd = pageStart - 1 + this._data.length;
            text = ` 
                Showing
                    <span class="font-medium">${pageStart}</span> to <span class="font-medium">${pageEnd}</span> of <span class="font-medium">${this._totalResultsCount}</span>
                results
            `;
        }
        this.pageTextElement.innerHTML = text;
    }

    private renderPageButtons() {
        // opacity-30
        // hover:bg-gray-50 cursor-pointer
        const { size, number } = this.pageOptions;
        const nextStart = number * size + 1;
        const previousDisabled = number <= 1;
        const nextDisabled = nextStart > this._totalResultsCount;
        this.prevButtonElement.removeEventListener('click', this.prevClickFn);
        this.nextButtonElement.removeEventListener('click', this.nextClickFn);
        this.prevButtonElement.classList.add('opacity-30', 'hover:bg-gray-50', 'cursor-pointer', 'cursor-not-allowed');
        this.nextButtonElement.classList.add('opacity-30', 'hover:bg-gray-50', 'cursor-pointer', 'cursor-not-allowed');

        if (previousDisabled) {
            this.prevButtonElement.classList.remove('hover:bg-gray-50', 'cursor-pointer');
        } else {
            this.prevButtonElement.classList.remove('opacity-30', 'cursor-not-allowed');
            this.prevButtonElement.addEventListener('click', this.prevClickFn);
        }

        if (nextDisabled) {
            this.nextButtonElement.classList.remove('hover:bg-gray-50', 'cursor-pointer');
        } else {
            this.nextButtonElement.classList.remove('opacity-30', 'cursor-not-allowed');
            this.nextButtonElement.addEventListener('click', this.nextClickFn);
        }
    }

    private nextClick() {
        this.pageOptions.number = this.pageOptions.number + 1;
        this.nextButtonElement.removeEventListener('click', this.nextClickFn);
        this.dispatchPageChange();
    }

    private dispatchPageChange(): void {
        if (typeof this.onPageChange == 'function') {
            this.onPageChange(this.pageOptions);
        }
    }

    private prevClick() {
        this.pageOptions.number = this.pageOptions.number - 1;
        this.nextButtonElement.removeEventListener('click', this.prevClickFn);
        this.dispatchPageChange();
    }

    private pageSizeChanged(size: number) {
        if (this.pageOptions.size != size) {
            this.pageOptions.number = 1;
        }
        this.pageOptions.size = size;
        this.dispatchPageChange();

        this.renderPageSize();
    }

    private renderPageSize() {
        this.pageSizeElement.innerText = this.pageOptions.size.toString();
    }

    private renderContainer() {
        this.container.innerHTML = '';
        const container = this.createContainer();
        this.container.append(container);
        this.rowsContainer = container.querySelector('[bk-data-rows]');
    }

    private renderRows() {
        this.rowsContainer.innerHTML = '';
        this.rowStates = [];
        this.data.forEach((rowData) => {
            const rowState = this.renderRow(rowData);
            this.rowStates.push(rowState);
            this.rowsContainer.append(rowState.element);
        });

        if (!this.showEmptyState) {
            this.rowsContainer.innerHTML = `<div class="flex w-full h-full items-center justify-center animate-pulse opacity-50">
            <svg width="49.5" height="72.3" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1">
             <g>
              <title>Layer 1</title>
              <g id="svg_1">
               <path id="svg_2" fill="#7F7F7F" d="m-0.4,71.7c0,-10.4 8.4,-18.8 18.8,-18.8l30.5,0c0,10.4 -8.4,18.8 -18.8,18.8l-30.5,0z" class="st0"/>
               <path id="svg_3" fill="#7F7F7F" d="m30.1,31.1l-11.7,0l0,18.8l30.5,0c0,-10.4 -8.4,-18.8 -18.8,-18.8z" class="st0"/>
               <path id="svg_4" fill="#7F7F7F" d="m18.4,19.4c0,-10.4 -8.4,-18.8 -18.8,-18.8l0,30.5l18.8,0l0,-11.7z" class="st0"/>
               <path id="svg_5" d="m-0.4,31.1c0,10.4 8.4,18.8 18.8,18.8l0,-18.8l-18.8,0z"/>
              </g>
             </g>
           </svg>
           </div>`;
        }
        if (this.data.length == 0 && this.showEmptyState) {
            let content = 'No records found';
            if (typeof this.noRecordsTemplate === 'function') {
                content = this.noRecordsTemplate();
            }
            this.rowsContainer.innerHTML += content;
        }

        const titleContainer = this.container.querySelector('[bk-column-headers]') as HTMLElement;
        if (this.rowsContainer.scrollHeight > this.rowsContainer.clientHeight) {
            titleContainer.style.paddingRight = '17px';
        } else {
            titleContainer.style.paddingRight = 'unset';
        }
    }

    private renderRow(rowData: T) {
        const rowState = new GridRow<T>();
        rowState.data = rowData;
        rowState.disabled = typeof this.isRowDisabled === 'function' ? this.isRowDisabled(rowData) : false;

        const row = createElement('<div class="flex flex-row border-b"></div>');
        this.columns.forEach((column) => {
            let cell: HTMLElement;
            const add_class = column.hideColumn ? 'hidden' : '';
            if (column.type === 'checkbox') {
                cell = createElement(`
                        <div class="px-4 pr-6 py-2 sm:py-4 mr-auto sm:mr-0 flex items-center ${add_class}"></div>
                    `);

                const checkbox = createElement<HTMLInputElement>(`
                        <input type="checkbox">
                    `);

                rowState.checkbox = checkbox;

                if (rowState.disabled) {
                    checkbox.disabled = true;
                } else {
                    checkbox.addEventListener('click', (e: Event) => {
                        rowState.selected = (e.target as HTMLInputElement).checked;
                        this.handleSelectAll();
                        this.emitSelectionChange();
                    });
                }

                cell.appendChild(checkbox);
            } else if (column.type === 'actions') {
                cell = createElement(
                    `<div class="px-4 py-3 sm:px-6 w-24 flex justify-center items-center text whitespace-nowrap text-sm text-gray-500 ${add_class}">
                            <div class="relative flex items-center bg-white grid-actions">
                                
                            </div>
                        </div>`
                );
                const button = createElement(
                    `<div class="flex-shrink-0 flex items-center justify-center font-medium">
                            <button type="button" class="h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500" id="activity-menu-nO6vjabaP1yL%" aria-expanded="false" aria-haspopup="true" data-target="dropdown.button" data-action="click->dropdown#toggleMenu">
                                <span class="sr-only"></span>
                                <svg height="24px" width="24px" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="text-black hover:text-bkblue">
                                <circle cx="12" cy="18" r="2"></circle>
                                <circle cx="12" cy="12" r="2"></circle>
                                <circle cx="12" cy="6" r="2"></circle>
                                </svg> 
                            </button>
                        </div>`
                );
                cell.appendChild(button);

                const actions: DropdownItem[] = [];
                column.actions?.forEach((actionConfig) => {
                    actions.push({
                        title: actionConfig.title,
                        disabled:
                            typeof actionConfig.disabled === 'function'
                                ? actionConfig.disabled(rowData)
                                : (actionConfig.disabled as boolean),
                        class:
                            typeof actionConfig.class === 'function' ? actionConfig.class(rowData) : actionConfig.class,
                        onClick: () => {
                            typeof actionConfig.onClick === 'function' && actionConfig.onClick(rowData);
                        },
                        tooltip: actionConfig.tooltipMessage,
                    });
                });
                new Dropdown({
                    element: button,
                    items: actions,
                });
                row.appendChild(cell);
            } else {
                const flexAlignX: string = this.getFlexAlignX(column.alignY);
                cell = createElement(`
                        <div class="px-2 py-4 text-sm text-gray-900 flex ${flexAlignX} ${add_class}"></div>
                    `);
                if (column.width) {
                    cell.style.width = column.width;
                } else {
                    cell.classList.add('flex-1');
                }

                if (column.customRowClasses) {
                    cell.classList.add(column.customRowClasses);
                }

                let innerContent: Node;
                if (column.cellTemplate && typeof column.cellTemplate === 'function') {
                    const userContent = column.cellTemplate(rowData);

                    if (typeof userContent === 'string') {
                        innerContent = createElement(userContent);
                    } else {
                        innerContent = userContent;
                    }
                } else {
                    innerContent = createElement('<span>' + rowData[column.field] + '</span>');
                }
                const cellDisabled = column.isCellDisabled?.(rowData);
                if (typeof column.onCellClicked === 'function' && !cellDisabled) {
                    innerContent.addEventListener('click', () => {
                        column.onCellClicked(rowData);
                    });
                }
                if (typeof column.onContextMenu === 'function' && !cellDisabled) {
                    innerContent.addEventListener('contextmenu', (e) => {
                        e.preventDefault();
                        column.onContextMenu(rowData);
                    });
                }
                innerContent && cell.appendChild(innerContent);
            }
            row.appendChild(cell);
        });

        rowState.element = row;
        return rowState;
    }

    private getFlexAlignX(alignX: string): string {
        const align = alignX || 'center';
        return align == 'center'
            ? 'items-center'
            : align == 'left'
            ? 'items-start'
            : align == 'right'
            ? 'items-end'
            : 'items-center';
    }

    private handleSelectAll(): void {
        const selectedLength = this.rowStates.filter((x) => x.selected).length;
        const selectableLength = this.rowStates.filter((x) => !x.disabled).length;
        this.selectAllChecked = selectedLength > 0 && selectedLength == selectableLength;

        this.rowStates.forEach(({ checkbox, selected }) => {
            checkbox.checked = selected;
        });

        this.selectAllBox.checked = this.selectAllChecked;
    }

    private emitSelectionChange(): void {
        if (typeof this.onSelectionChange === 'function') {
            const newSelection = this.rowStates.filter((x) => x.selected);
            this.onSelectionChange(newSelection);
        }
    }

    updateRowData(rowData: T): void {
        const itemIdx = this.rowStates.findIndex((state) => state.data[this.key] == rowData[this.key]);
        if (itemIdx >= 0) {
            const newState = this.renderRow(rowData);
            this.rowsContainer.replaceChild(newState.element, this.rowStates[itemIdx].element);
            this.rowStates[itemIdx] = newState;
        }
    }

    resetSelection(): void {
        this.rowStates.forEach((row) => (row.selected = false));
        this.handleSelectAll();
        this.emitSelectionChange();
    }
}
