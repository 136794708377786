import search_controller from './search_controller';

export default class extends search_controller {
    static targets = ['organizations'];
    fetchOrganizations(e) {
        let val = e.target.value;
        let context = this;
        this.ajax({
            url: `/company/${val || 0}/fetch_organizations`,
            type: 'GET',
            success: function (data) {
                context.organizationsTarget.innerHTML = '';
                for (let i = 0; i < data['organizations'].length; i++) {
                    let newOption = new Option(data['organizations'][i][0], data['organizations'][i][1]);
                    context.organizationsTarget.add(newOption, undefined);
                }
            },
        });
    }
}