import ApplicationController from './application_controller';
export default class extends ApplicationController {
    static targets = ['password', 'show', 'hide'];

    showPassword() {
        this.passwordTarget.type = 'text';
        this.hideTarget.classList.remove('hidden');
        this.showTarget.classList.add('hidden');
    }

    hidePassword() {
        this.passwordTarget.type = 'password';
        this.showTarget.classList.remove('hidden');
        this.hideTarget.classList.add('hidden');
    }
}
