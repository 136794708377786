import ApplicationController from './application_controller';
export default class extends ApplicationController {
    static targets = ['form', 'card', 'errors', 'cardHolderName', 'postalCode', 'submit', 'clientSecret'];

    static values = {
        postSubmitUrl: String,
        loadOnConnect: {
            type: Boolean,
            default: false,
        },
    };

    initialize() {
        var context = this;
        this.formTarget.addEventListener('stripe:clear-card', function () {
            context.cardTarget.isValid = true;
            context.clearBillingCardDetails();
            context.sendEvent(context.formTarget, 'form:check-required-fields');
        });
    }

    connect() {
        if (this.loadOnConnectValue) {
            this.stripeConnect();
        }
    }

    stripeConnect() {
        if (window['Stripe']) {
            this.stripe = window['Stripe'](this.data.get('public-key'), {
                // betas: ['elements_enable_deferred_intent_beta_1'],
            });
            const context = this;
            const elements = this.stripe.elements({
                clientSecret: this.clientSecretTarget.value,
                loader: 'always',
                // mode: 'setup',
                // currency: 'usd',
                // payment_method_types: ['card', 'us_bank_account'],
            });
            this.elements = elements;
            const style = JSON.parse(this.data.get('style'));

            this.card = elements.create('payment', {
                fields: {
                    billingDetails: {
                        name: 'never',
                        //  email: 'never'
                    },
                },
            });
            this.card.on('change', (e) => context.change(e));
            this.card.mount(this.cardTarget);
            this.cardTarget.isValid = false;
            window.cc = this.card;
            window.ss = this.stripe;
            this.sendEvent(this.formTarget, 'form:check-required-fields');
        } else {
            console.log('loading stripe');
            const context = this;
            const element = document.createElement('script');
            element.src = 'https://js.stripe.com/v3/';
            element.onload = function () {
                context.stripeConnect();
            };
            document.body.appendChild(element);
        }
    }

    clearBillingCardDetails() {
        this.clearCard();
        if (this.hasCardHolderNameTarget && this.cardHolderNameTarget.dataset.clear == 'true') {
            this.clearName();
        }
        this.clearErrors();
    }

    clearCard() {
        this.card.clear();
    }

    clearName() {
        this.cardHolderNameTarget.value = '';
    }

    clearErrors() {
        this.errorsTarget.textContent = '';
        this.errorsTarget.classList.remove('error');
    }

    change(event) {
        this.sendEvent(this.formTarget, 'change-tracker:markDirty');
        this.cardTarget.isValid = false;
        if (event.error) {
            this.errorsTarget.textContent = event.error.message;
            this.errorsTarget.classList.add('error');
        } else {
            this.clearErrors();
            if (event.complete) {
                this.cardTarget.isValid = true;
            }
        }
        this.sendEvent(this.formTarget, 'form:check-required-fields');
    }

    stripeTokenHandler(result) {
        let context = this;
        const formData = new FormData(this.formTarget);
        formData.append('payment_method_id', result.setupIntent.payment_method);
        if (
            result.setupIntent.status == 'requires_action' &&
            result.setupIntent.next_action.type == 'verify_with_microdeposits'
        ) {
            formData.append('bank_account_status', 'verification_pending');
            formData.append(
                'verification_url',
                result.setupIntent.next_action.verify_with_microdeposits.hosted_verification_url
            );
        }
        this.errorsTarget.textContent = '';
        this.errorsTarget.classList.remove('error');
        fetch(
            this.formTarget.action, // store_payment_method
            {
                method: 'POST',
                headers: {
                    'x-csrf-token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    'X-Requested-With': 'XMLHttpRequest',
                    Accept: 'application/json',
                },
                body: formData,
            }
        )
            .then(function (res) {
                return res.json();
            })
            .then(function (data) {
                context.handleServerResponse(data);
            });
    }

    handleServerResponse(response) {
        if (response.error) {
            this.setSubmitDisabled(false);
            this.submitTarget.querySelector('span.label').textContent = 'SAVE';
            this.errorsTarget.textContent = response.error.message || response.error;
            this.errorsTarget.classList.add('error');
        } else if (response.requires_action) {
            // Use Stripe.js to handle required card action
            this.handleAction(response); // TODO CHECK
        } else {
            let loc = this.postSubmitUrlValue || window.location.href;
            window.Turbo.visit(loc);
        }
    }

    handleAction(response) {
        this.stripe.handleCardAction(response.payment_intent_client_secret).then(function (result) {
            if (result.error) {
                this.setSubmitDisabled(false);
                this.submitTarget.querySelector('span.label').textContent = 'Pay Now';
                this.errorsTarget.textContent = result.error.message;
                this.errorsTarget.classList.add('error');
            } else {
                fetch(
                    window.location, // TODO: Check functionality.
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-csrf-token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                        },
                        body: JSON.stringify({
                            payment_intent_id: result.paymentIntent.id,
                        }),
                    }
                )
                    .then(function (confirmResult) {
                        return confirmResult.json();
                    })
                    .then(this.handleServerResponse);
            }
        });
    }

    submit(event) {
        if (!this.cardTarget.isValid) {
            event.preventDefault();
            return;
        }
        if (this.submitTarget.disabled) return;

        this.formTarget.form_controller.submit(event);

        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();
        this.setSubmitDisabled(true);

        this.elements.submit().then(({ error }) => {
            if (error) {
                this.setSubmitDisabled(false);
                this.errorsTarget.textContent = error.message;
                this.errorsTarget.classList.add('error');
                return;
            }
            this.stripe
                .confirmSetup({
                    elements: this.elements,
                    clientSecret: this.clientSecretTarget.value,
                    redirect: 'if_required',
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                name: this.cardHolderNameTarget.value,
                            },
                        },
                    },
                })
                .then((result) => {
                    // Handle result.error or result.setupIntent
                    console.log(result);
                    if (result.error) {
                        this.setSubmitDisabled(false);
                        this.errorsTarget.textContent = result.error.message;
                        this.errorsTarget.classList.add('error');
                    } else {
                        this.stripeTokenHandler(result);
                        this.submitTarget.setAttribute('disabled', true);
                        this.submitTarget.classList.add('form-disabled');
                    }
                });
        });
    }

    setSubmitDisabled(disabled) {
        this.submitTarget.disabled = disabled;
        if (disabled) {
            this.submitTarget.classList.add('processing');
            this.submitTarget.classList.add('disabled');
        } else {
            this.submitTarget.classList.remove('processing');
            this.submitTarget.classList.remove('disabled');
        }
    }

    disconnect() {
        let frames = document.getElementsByTagName('iframe');
        let i = 0;
        while (i < frames.length) {
            if (frames[i].name.startsWith('__privateStripe')) {
                frames[i].remove();
            }
            i += 1;
        }
    }
}
