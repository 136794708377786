import ApplicationController from './application_controller';
import { stringify } from 'querystring';

export default class extends ApplicationController {
    static targets = [
        'initialEmail',
        'emailExistsError',
        'errorDisplay',
        'existingUser',
        'newUser',
        'newUserMessage',
        'firstName',
        'lastName',
        'userType',
        'userRole',
        'submit'
    ];

    static values = {
        url: {
            type: String,
            default: '/find_user',
        },
    };

    removeError() {
        if (this.hasEmailExistsErrorTarget) {
            this.emailExistsErrorTarget.classList.add('hidden');
        }
    }

    checkEmail() {
        if (!this.initialEmailTarget.isValid) {
            return;
        }
        var email = this.initialEmailTarget.value;
        var context = this;
        var valid = false;
        if (email.trim().length) {
            this.ajax({
                url: this.urlValue,
                type: 'POST',
                dataType: 'json',
                data: stringify({ email: email }),
                success: function (data) {
                    if (data.email_avail) {
                        valid = false;
                        if (context.hasEmailExistsErrorTarget) {
                            context.emailExistsErrorTarget.classList.remove('hidden');
                            context.errorDisplayTarget.innerText = data.email + ' already exists.';
                        } else {
                            context.existingUserTarget.classList.remove('hidden');
                            context.newUserTarget.classList.add('hidden');
                            context.newUserMessageTarget.classList.add('hidden');
                            if (context.hasFirstNameTarget) {
                                context.firstNameTarget.value = data.first_name;
                                context.firstNameTarget.disabled = true;
                                context.firstNameTarget.classList.add('bg-gray-100');
                            }
                            if (context.hasLastNameTarget) {
                                context.lastNameTarget.value = data.last_name;
                                context.lastNameTarget.disabled = true;
                                context.lastNameTarget.classList.add('bg-gray-100');
                            }
                            if (context.hasUserTypeTarget) {
                                context.userTypeTarget.value = '';
                            }
                            if (context.userRoleTarget.value != '') {
                                context.submitTarget.removeAttribute('disabled');
                                context.submitTarget.classList.remove('form-disabled');
                            }
                        }
                    } else {
                        valid = true;
                        if (context.hasEmailExistsErrorTarget) {
                            context.emailExistsErrorTarget.classList.add('hidden');
                        } else {
                            context.newUserTarget.classList.remove('hidden');
                            context.newUserMessageTarget.classList.remove('hidden');
                            context.existingUserTarget.classList.add('hidden');
                            if (context.hasFirstNameTarget) {
                                context.firstNameTarget.value = '';
                                context.firstNameTarget.disabled = false;
                                context.firstNameTarget.classList.remove('bg-gray-100');
                            }
                            if (context.hasLastNameTarget) {
                                context.lastNameTarget.value = '';
                                context.lastNameTarget.disabled = false;
                                context.lastNameTarget.classList.remove('bg-gray-100');
                            }
                            if (context.hasUserTypeTarget) {
                                context.userTypeTarget.value = 'NEW USER';
                            }
                        }
                    }
                },
            });
        }
        return valid;
    }
}
