import Flatpickr from 'stimulus-flatpickr';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin.js';
import { useClickOutside } from 'stimulus-use';

export default class extends Flatpickr {
    static targets = ['to'];

    connect() {
        this.config = {
            mode: 'range',
            plugins: [new rangePlugin({ input: this.toTarget })],
        };
        useClickOutside(this);
        super.connect();
    }

    change(values) {
        // Need to allow max range.
        if (values[0] && this.data.get('maxRangeDays')) {
            let newMaxDate = new Date(values[0]).setDate(values[0].getDate() + +this.data.get('maxRangeDays'));
            if (this.data.get('maxDate')) {
                let initialMaxDate = new Date(this.data.get('maxDate'));
                if (initialMaxDate < newMaxDate) {
                    // Do not allow more range than actual max date
                    return;
                }
            }
            this.fp.set('maxDate', newMaxDate);
        }
    }

    clear(e) {
        e.preventDefault();
        e.stopPropagation();
        this.fp.clear();
    }

    clickOutside() {}
}
