import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['selectLocations', 'select', 'allLocations', 'locationCbx', 'locationsList', 'submit'];

    connect() { }

    locationSelection() {
        if (this.allLocationsTarget.checked) {
            this.locationCbxTargets.forEach((element) => {
                element.checked = true;
            });
        } else {
            this.locationCbxTargets.forEach((element) => {
                element.checked = false;
            });
        }
        this.updateSelectionArray();
    }

    locationSelectionChanged(e) {
        if (!e.target.checked) {
            this.allLocationsTarget.checked = false;
        }
        this.updateSelectionArray();
    }

    updateSelectionArray() {
        let locations = [];
        this.locationCbxTargets.forEach((element) => {
            if (element.checked) {
                locations.push(element.name);
            }
        });
        if (this.allLocationsTarget.checked || locations.length > 0) {
            this.submitTarget.classList.remove('form-disabled');
            this.submitTarget.disabled = false;
        } else {
            this.submitTarget.classList.add('form-disabled');
            this.submitTarget.disabled = true;
        }
        this.locationsListTarget.value = locations;
    }
}
