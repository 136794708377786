import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['submit', 'showOrHide', 'toggleData'];

    static values = {
        changesMade: {
            type: Boolean,
            default: false,
        },
        confirmMessage: {
            type: String,
            default: 'Changes are not saved. Are you sure you want to exit?',
        },
        disableConfirmOnce: {
            type: Boolean,
            default: false,
        },
    };

    initialize() {
        this.bindConfirmChanges = this.confirmChanges.bind(this);
    }

    connect() {
        let context = this;
        document.addEventListener('turbo-tabs:change', this.bindConfirmChanges);
        document.addEventListener('turbo:before-fetch-request', function (e) {
            if (!e.detail.url.href.includes('categories')) {
                context.confirmChanges(e);
            }
        });
        window.addEventListener('beforeunload', this.bindConfirmChanges);
        this.element.addEventListener('change-tracker:check-changes', function (e) {
            context.confirmChanges(e);
        });
        this.element.addEventListener('change-tracker:markDirty', function () {
            context.markDirty();
        });

        this.element.addEventListener('change-tracker:mark-pristine', function () {
            context.markPristine();
        });
    }

    disconnect() {
        document.removeEventListener('turbo-tabs:change', this.bindConfirmChanges);
        document.removeEventListener('turbo:before-fetch-request', this.bindConfirmChanges);
        window.removeEventListener('beforeunload', this.bindConfirmChanges);
    }

    markDirty() {
        this.changesMadeValue = true;
        this.element.dataset.dirty = true;
    }

    markPristine() {
        this.changesMadeValue = false;
        this.element.dataset.dirty = false;
    }

    disableConfirm(e) {
        if (e && e.type == 'submit' && e.defaultPrevented) return;

        this.changesMadeValue = false;
        this.element.dataset.dirty = false;
    }

    disableDependentConfirm(e) {
        if (e && e.type == 'submit' && e.defaultPrevented) return;

        if (e.params.dependant) {
            const el = document.querySelector(e.params.dependant);
            if (el) {
                const tracker = this.application.getControllerForElementAndIdentifier(el, 'change-tracker');
                if (e.params.dependantDisableConfirm) {
                    tracker.disableConfirm();
                } else {
                    tracker.disableConfirmOnce();
                }
            }
        }
    }

    disableConfirmOnce() {
        this.disableConfirmOnceValue = true;
    }

    confirmChanges(e) {
        if (this.disableConfirmOnceValue) {
            this.disableConfirmOnceValue = false;
            return true;
        }
        if (this.changesMadeValue) {
            if (e.type == 'beforeunload') {
                e.returnValue = this.confirmMessageValue;
            } else {
                let res = confirm(this.confirmMessageValue);
                if (!res) {
                    e.stopImmediatePropagation();
                    e.stopPropagation();
                    e.preventDefault();
                    if (e.detail && e.detail.fetchOptions) {
                        const turboFrame = e.detail.fetchOptions.headers['Turbo-Frame'];
                        if (turboFrame) {
                            document.getElementById(turboFrame).removeAttribute('data-busy', '');
                        }
                    }
                } else {
                    this.changesMadeValue = false;
                }
                return res;
            }
        } else {
            return true;
        }
    }

    disableEstimatedEntities() {
        var selectedValue = this.toggleDataTarget.value;
        if(selectedValue == 'business') {
            this.showOrHideTarget.classList.add('hidden');
        } else {
            this.showOrHideTarget.classList.remove('hidden');
        }
    }
}
