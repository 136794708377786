import search_controller from './search_controller';

export default class extends search_controller {
    static targets = ['company', 'organizations'];

    connect() {
        this.updateEntities();
    }

    updateEntities() {
        if (this.hasCompanyTarget) {
            let companyId = this.companyTarget.value || null;
            let entitiesData = JSON.parse(this.companyTarget.dataset.companyEntityData);
            let updatedEntities = entitiesData.filter((entity) => entity['company_id'] == companyId);
            this.organizationsTarget.innerHTML = '';

            updatedEntities.forEach((entity) => {
                let newOption = new Option(entity['name'], entity['id']);
                this.organizationsTarget.add(newOption, undefined);
            });
        }
    }
}
