import SearchController from './search_controller';

export default class extends SearchController {
    static targets = ['search', 'noResults', 'option'];

    initialize() {
        this.highlightFirstOption();
    }

    highlightSelectedOption() {
        for (let i = 0; i < this.optionTargets.length; i++) {
            if (this.optionTargets[i].dataset.orgIdyValue && this.optionTargets[i].dataset.selectedOrgIdyValue) {
                if (this.optionTargets[i].dataset.orgIdyValue == this.optionTargets[i].dataset.selectedOrgIdyValue) {
                    this.optionTargets[i].classList.add('selected');
                    this.highlightOption(this.optionTargets[i]);
                    return;
                }
            }
        }
        this.highlightFirstOption();
    }

    highlightFirstOption() {
        var first = this.optionTargets.find((x) => !x.classList.contains('hidden'));
        first && this.highlightOption(first);
    }

    userInput() {
        super.userInput();
        if (this.optionTargets.find((x) => x.classList.contains('hidden') && x.classList.contains('highlight')))
            this.highlightFirstOption();
        return true;
    }

    dataLoaded() {
        super.userInput();
        this.highlightSelectedOption();
    }

    highlightOption(opt) {
        this.optionTargets.forEach((x) => x.classList.remove('highlight'));
        const scrollParent = opt.parentElement.hasAttribute('data-lazy-loader-target')
            ? opt.parentElement.parentElement
            : opt.parentElement;
        if (opt) {
            let scrollOffset = opt.offsetTop - scrollParent.offsetTop;
            if (
                scrollOffset + opt.getBoundingClientRect().height >
                scrollParent.scrollTop + scrollParent.getBoundingClientRect().height
            ) {
                scrollParent.scrollTo({
                    top:
                        scrollOffset + opt.getBoundingClientRect().height - scrollParent.getBoundingClientRect().height,
                });
            }
            if (scrollOffset < scrollParent.scrollTop) {
                scrollParent.scrollTo({
                    top: scrollOffset,
                });
            }
            opt.classList.add('highlight');
        }
    }

    hoverHighlight(e) {
        this.highlightOption(e.target);
    }

    arrowHandle(e) {
        if (e.which == 38 || e.which == 40 || e.which == 13) {
            let optionTargets = this.optionTargets.filter((x) => !x.classList.contains('hidden'));

            let selIdx = optionTargets.findIndex((x) => x.classList.contains('highlight'));
            let selected = optionTargets[selIdx];
            if (selected) {
                if (e.which == 13) {
                    // Enter key
                    optionTargets[selIdx].click();
                    if (this.hasButtonTarget) this.buttonTarget.focus();
                } else if (e.which == 38 && selIdx) {
                    // Up key
                    if (selIdx > 0) {
                        this.highlightOption(optionTargets[selIdx - 1]);
                    }
                } else if (e.which == 40) {
                    // Down key
                    if (selIdx < optionTargets.length - 1) {
                        this.highlightOption(optionTargets[selIdx + 1]);
                    }
                }
            }
        } else {
            return true;
        }
    }
}
