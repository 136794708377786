import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['control', 'hint'];

    static values = {
        valid: {
            default: true,
            type: Boolean,
        },
    };

    connect() {
        let context = this;
        this.controlTarget.isValid = true;
        this.element.addEventListener('input:clear-hint', function () {
            context.clearHint();
        });
        this.element.addEventListener('input:show-hint', function (e) {
            context.showHint(e.detail);
        });
        this.element.addEventListener('input:set-control-valid', function (e) {
            context.setControlValid(e.detail.valid);
        });
    }

    showHint(hint) {
        if (this.hasHintTarget) {
            if (hint.success) {
                this.hintTarget.classList.add('success');
                this.hintTarget.innerHTML = hint.success;
            } else if (hint.warning) {
                this.hintTarget.classList.add('warning');
                this.hintTarget.innerHTML = hint.warning;
                this.setControlValid(true);
            } else {
                this.hintTarget.classList.add('error');
                this.hintTarget.innerHTML = hint.error || hint;
                this.setControlValid(false);
            }
        }
    }

    clearHint() {
        if (this.hasHintTarget) {
            this.hintTarget.innerHTML = '';
            this.hintTarget.classList.remove('success');
            this.hintTarget.classList.remove('error');
            this.hintTarget.classList.remove('warning');
        }
        this.setControlValid(true);
    }

    setControlValid(isValid) {
        if (this.hasControlTarget) {
            this.controlTarget.isValid = isValid;
            this.controlTarget.isPending = false;
        }
        this.validValue = isValid;
    }
}
