import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['copyText'];
    static values = {
        hideCopiedText: {
            type: Boolean,
            default: false,
        },
    };

    copy(event) {
        event.preventDefault();
        event.stopPropagation();
        const value =
            this.copyTextTarget.tagName == 'INPUT' ? this.copyTextTarget.value : this.copyTextTarget.innerText;
        navigator.clipboard.writeText(value);
        const message = this.hideCopiedTextValue
            ? 'Copied to the clipboard!'
            : `Copied to the clipboard!<br><span class='text-xs'>${value}</span>`;
        this.showAlert(message, { type: 'info' });
    }
}
