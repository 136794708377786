import { createPopper, Instance } from '@popperjs/core';
import { createElement } from '@utils';

interface TooltipOptions {
    content: string | HTMLElement;
    element: HTMLElement;
    wide?: boolean;
    class?: string;
    placement?: 'left' | 'right' | 'top' | 'bottom';
    offset?: [number, number];
}

export default class Tooltip {
    options: TooltipOptions;
    element: HTMLElement;
    popperInstance: Instance;
    tooltipTarget: HTMLElement;
    scrollParent: HTMLElement;
    observer: MutationObserver;

    constructor(options: TooltipOptions) {
        this.element = options.element;
        this.options = options;

        if (!this.element) {
            throw new Error('element option is required for tooltip');
        }

        this.element.addEventListener('mouseenter', () => this.show());
        this.element.addEventListener('mouseleave', () => this.hide());
    }

    show() {
        if (!this.popperInstance) {
            this.setPopperInstance();
            this.scrollParent = this.getScrollParent(this.options.element);
        }

        this.appendTooltip();

        this.tooltipTarget.setAttribute('data-show', '');

        // We need to tell Popper to update the tooltip position
        // after we show the tooltip, otherwise it will be incorrect
        this.popperInstance?.update();
        this.addObserver();
    }

    private addObserver() {
        if (!this.observer) {
            this.observer = new MutationObserver((mutations_list) => {
                mutations_list.forEach((mutation) => {
                    mutation.removedNodes.forEach((removed_node) => {
                        if (removed_node == this.element || removed_node.contains(this.element)) {
                            this.hide();
                        }
                    });
                });
            });
    
            this.observer.observe(document.body, { subtree: true, childList: true });
        }
    }

    appendTooltip() {
        document.body.appendChild(this.tooltipTarget);

        this.scrollParent?.addEventListener('scroll', this.hide);
    }

    hide = () => {
        this.tooltipTarget.parentElement?.removeChild(this.tooltipTarget);
        this.tooltipTarget.removeAttribute('data-show');
        this.scrollParent?.removeEventListener('scroll', this.hide);
        this.observer?.disconnect();
        this.observer = null;
    };

    setPopperInstance() {
        this.tooltipTarget = this.getTooltipTemplate(this.options.content);

        this.popperInstance = createPopper(this.element, this.tooltipTarget, {
            placement: this.options.placement || 'right',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: this.options.offset || [0, 8],
                    },
                },
            ],
        });
    }

    getTooltipTemplate(tooltipValue) {
        const popperWide = this.options.wide == true ? 'popper-wide' : '';
        return createElement(
            '<div class="tooltip ' +
                this.options.class +
                ' ' +
                popperWide +
                '" role="tooltip">' +
                tooltipValue +
                '<div class="arrow" data-popper-arrow></div>' +
                '</div>'
        );
    }

    getScrollParent(node: HTMLElement) {
        if (node == null) {
            return null;
        }

        if (node.scrollHeight > node.clientHeight) {
            return node;
        } else {
            return this.getScrollParent(node.parentElement);
        }
    }
}
