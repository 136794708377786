const charRegex = /^([A-Za-z])/;
const numRegex = /^([0-9])/;

const validators = {
    US: {
        regex: /^\d{5}$/,
        input: (value) => {
            if (value.length <= 5) {
                for (let i = 0; i < value.length; i++) {
                    if (!numRegex.test(value[i])) return false;
                }
            }
            if (value.length >= 4)
            {
                let regex = /^\d{5}$/;
                return regex.test(value);
            }
            return true
        },
    },
    CA: {
        regex: /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/,
        input: (value) => {
            let count = 0,
                spaces = 0;
            for (let i = 0; i < value.length; i++) {
                if (
                    ((i - spaces) % 2 === 0 && charRegex.test(value[i])) ||
                    ((i - spaces) % 2 === 1 && numRegex.test(value[i])) ||
                    value[i] === ' '
                ) {
                    if (value[i] !== ' ') count++;
                    else spaces++;
                    if (count > 6) return false;
                    continue;
                } else {
                    return false;
                }
            }
            return true;
        },
    },
    GB: {
        regex: /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/,
        input: (value) => {
            if (value.length === 0) {
                return true;
            }
            let regex = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;
            return regex.test(value);
        },
    },
    FR: {
        regex: /^\d{5}$/,
        input: (value) => {
            if (value.length === 0) {
                return true;
            }
            let regex = /^\d{5}$/;
            return regex.test(value);
        },
    },
    DE: {
        regex: /^\d{5}$/,
        input: (value) => {
            if (value.length === 0) {
                return true;
            }
            let regex = /^\d{5}$/;
            return regex.test(value);
        },
    },
    ES: {
        regex: /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/,
        input: (value) => {
            if (value.length === 0) {
                return true;
            }
            let regex = /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/;
            return regex.test(value);
        },
    },
    IE: {
        regex: /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i,
        input: (value) => {
            if (value.length === 0) {
                return true;
            }
            let regex = /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i;
            return regex.test(value);
        },
    },
    AU: {
        regex: /^\d{4}$/,
        input: (value) => {
            if (value.length === 0) {
                return true;
            }
            let regex = /^\d{4}$/;
            return regex.test(value);
        },
    },
    NZ: {
        regex: /^\d{4}$/,
        input: (value) => {
            if (value.length === 0) {
                return true;
            }
            let regex = /^\d{4}$/;
            return regex.test(value);
        },
    },

};

export default validators;
