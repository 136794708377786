import ApplicationController from './application_controller';
import { enter, leave } from 'el-transition';
import { useClickOutside, useHotkeys } from 'stimulus-use';

export default class extends ApplicationController {
    static targets = ['menu', 'button'];

    initialize() {
        useClickOutside(this, { onlyVisible: false });
    }

    connect() {
        useHotkeys(this, {
            escape: [this.escapeClicked],
        });
    }

    // call the enter and leave functions
    toggleMenu() {
        // super.sayHi();
        let x;
        x = this.getScrollParent(this.element);
        if (this.menuTarget.classList.contains('hidden')) {
            let context = this;
            enter(this.menuTarget).then(function () {
                if (context.menuTarget.querySelector('input[type="text"]')) {
                    context.menuTarget.querySelector('input[type="text"]').focus();
                }
            });
            if (
                this.element.getBoundingClientRect().top - x.getBoundingClientRect().top >
                    this.menuTarget.offsetHeight &&
                x.getBoundingClientRect().bottom - this.element.getBoundingClientRect().bottom <
                    this.menuTarget.offsetHeight
            ) {
                this.menuTarget.style.bottom = this.element.clientHeight + 'px';
                this.menuTarget.style.top = 'unset';
            }
        } else {
            this.closeMenu();
        }
    }

    getScrollParent(element) {
        if (element == document.body) {
            return document.body;
        }
        if (element.classList.contains('dd-scroll-container')) {
            return element;
        } else {
            return this.getScrollParent(element.parentNode);
        }
    }

    closeMenu() {
        this.sendEventListener(this.menuTarget, 'dropdown:close');
        leave(this.menuTarget);
        this.menuTarget.style.bottom = null;
        this.menuTarget.style.top = null;
    }

    escapeClicked() {
        if (!document.querySelector('#turbo-container').classList.contains('hidden')) {
            return;
        }
        this.closeMenu();
    }

    clickOutside(e) {
        if (!this.menuTarget.classList.contains('hidden')) {
            if (
                (e.target.closest('#turbo-container') ||
                    e.target.closest('[data-action*="controls--turbo-popup#close"]')) &&
                !this.element.closest('#turbo-container')
            ) {
                return;
            }
            this.closeMenu();
        }
    }
}
