import { Tooltip } from '@components';
import { createElement } from '@utils';
import clickOutside from 'click-outside';
import { enter, leave } from 'el-transition';
import { DropdownItem } from './types';

interface DropdownOptions {
    items: DropdownItem[];
    element: Element;
}

export default class Dropdown {
    private options: DropdownOptions;
    private dropdownElement: HTMLElement;
    private isHidden = true;
    private unbindClickoutside;

    constructor(options: DropdownOptions) {
        this.options = options;
        this.options.element.addEventListener('click', () => this.open());
    }

    onClickOutside = (e) => {
        if (!this.dropdownElement.classList.contains('hidden')) {
            if (
                (e.target.closest('#turbo-container') ||
                    e.target.closest('[data-action*="controls--turbo-popup#close"]')) &&
                !this.options.element.closest('#turbo-container')
            ) {
                return;
            }
            this.close();
        }
    };

    open = () => {
        if (!this.dropdownElement) {
            this.constructDropdown();
        }

        if (this.isHidden) {
            enter(this.dropdownElement);
            this.unbindClickoutside = clickOutside(this.options.element, this.onClickOutside);
            this.handleScrollParent();
            this.isHidden = false;
        }
    };

    private handleScrollParent() {
        const scrollParent = this.getScrollParent(this.options.element);
        if (this.isHidden) {
            if (
                this.options.element.getBoundingClientRect().top - scrollParent.getBoundingClientRect().top >
                    this.dropdownElement.offsetHeight &&
                scrollParent.getBoundingClientRect().bottom - this.options.element.getBoundingClientRect().bottom <
                    this.dropdownElement.offsetHeight
            ) {
                this.dropdownElement.style.bottom = this.options.element.clientHeight + 'px';
                this.dropdownElement.style.top = 'unset';
            }
        }
    }

    close(): void {
        if (!this.isHidden) {
            this.isHidden = true;
            leave(this.dropdownElement);
            this.unbindClickoutside();
        }
    }

    toggle(): void {
        this.isHidden ? this.open() : this.close();
    }

    private constructDropdown() {
        this.dropdownElement = createElement(
            '' +
                '<div class="hidden origin-top-right absolute right-0 top-10 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 opacity-0 z-10" ' +
                '   role="menu" ' +
                '   data-transition-enter="transition ease-out duration-100" ' +
                '   data-transition-enter-start="transform opacity-0 scale-95" ' +
                '   data-transition-enter-end="transform opacity-100 scale-100" ' +
                '   data-transition-leave="transition ease-in duration-75" ' +
                '   data-transition-leave-start="transform opacity-100 scale-100"' +
                '   data-transition-leave-end="transform opacity-0 scale-95">' +
                '</div>'
        );

        this.options.items?.forEach((item: DropdownItem) => {
            const menuItem = createElement(`
                    <div class="text-left px-4 py-2 bg-white text-gray-700 hover:bg-gray-100 p-1 w-full ${
                        item.class || ''
                    }">
                        ${item.title}
                    </div>
            `);

            if (item.tooltip) {
                new Tooltip({
                    element: menuItem,
                    content: item.tooltip,
                    class: 'whitespace-normal',
                });
            }

            let isDisabled = false;

            if (typeof item.disabled === 'boolean') {
                isDisabled = item.disabled;
            } else if (typeof item.disabled === 'function') {
                isDisabled = item.disabled();
            }
            if (isDisabled) {
                menuItem.classList.add('text-gray-400');
            } else {
                menuItem.classList.add('cursor-pointer');
                if (typeof item.onClick === 'function') {
                    menuItem.addEventListener('click', (e: Event) => {
                        item.onClick(e);
                    });
                }
            }
            this.dropdownElement.appendChild(menuItem);
        });

        this.options.element.classList.add('relative');

        this.options.element.appendChild(this.dropdownElement);
    }

    getScrollParent(element) {
        if (element == document.body) {
            return document.body;
        }
        if (element.classList.contains('dd-scroll-container')) {
            return element;
        } else {
            return this.getScrollParent(element.parentNode);
        }
    }
}
