/* eslint-disable no-constant-condition */
import { Controller } from 'stimulus';

export default class extends Controller {
    initialize() {
        if (!this.element.dataset.action) {
            this.element.dataset.action = 'confirm#confirm';
        }
    }

    confirm(e) {
        let message = this.element.dataset.confirm;
        const matches = message.matchAll('{{[^}]*}}');
        while (true) {
            let currentMatch = matches.next();
            if (currentMatch.done) {
                break;
            }
            message = message.replace(
                currentMatch.value[0],
                document.querySelector(currentMatch.value[0].replace('{{', '').replace('}}', '')).value
            );
        }
        let res = this.data.get('type') == 'alert' ? alert(message) : confirm(message);
        if (!res) {
            e.preventDefault();
            e.confirmCancelled = true;
        }
        return res;
    }
}
