import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['search', 'noResults', 'group'];

    connect() {
        super.connect();
        this.userInput();
    }

    userInput() {
        var val = this.searchTarget.value;
        this.search(val);
    }

    reset() {
        this.searchTarget.value = '';
        this.userInput();
    }

    search(val) {
        const isGrouped = this.hasGroupTarget;

        if (this.hasNoResultsTarget) {
            this.noResultsTarget.classList.add('hidden');
        }
        val = (val || '').toLowerCase().replace(/\s/g, '');
        var allSearchItems = this.element.querySelectorAll('[data-search-value]');
        allSearchItems.forEach((x) => x.classList.remove('hidden'));
        if (isGrouped) {
            if (val) {
                this.groupTargets.forEach((x) => x.classList.add('hidden'));
            } else {
                this.groupTargets.forEach((x) => x.classList.remove('hidden'));
            }
        }
        let hasResults = true;
        if (val) {
            hasResults = false;
            allSearchItems.forEach((x) => {
                const itemGroup = x.getAttribute('data-search-group');
                var attrVal = x.getAttribute('data-search-value');
                x.classList.add('hidden');
                if (attrVal.indexOf(val) > -1) {
                    x.classList.remove('hidden');
                    if (isGrouped) {
                        const group = this.groupTargets.filter(
                            (x) => x.getAttribute('data-search-group') == itemGroup
                        )[0];
                        group && group.classList.remove('hidden');
                    }
                    hasResults = true;
                }
            });
        } else {
            allSearchItems.forEach((x) => {
                if (x.getAttribute('data-only-on-search') === 'true') {
                    x.classList.add('hidden');
                }
            });
        }
        if (!hasResults && this.hasNoResultsTarget) {
            this.noResultsTarget.classList.remove('hidden');
        }
    }
}
