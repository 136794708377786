import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['comment', 'reason'];

    handleReasonChange() {
        if (this.reasonTarget.value == 'Other') {
            this.commentTarget.setAttribute('required', 'required');
            this.commentTarget.setAttribute('minlength', '10');
        } else {
            this.commentTarget.removeAttribute('minlength');
            this.commentTarget.removeAttribute('required');
        }
    }
}
