import ApplicationController from './application_controller';
import { stringify } from 'querystring';

export default class extends ApplicationController {
    static targets = ['wand', 'select'];

    static values = {
        source: {
            type: String,
            default: 'qb',
        },
    };

    connect() {
        super.connect();
    }

    magic(e) {
        e.preventDefault();
        const context = this;
        const label = this.wandTarget.getAttribute('data-label');
        const acc = this.wandTarget.getAttribute('data-account');
        const org = this.wandTarget.getAttribute('data-org');
        const platform_name = this.sourceValue ? this.sourceValue.charAt(0).toUpperCase() + this.sourceValue.slice(1) : '';

        if (!e.confirmCancelled) {
            this.ajax({
                url: `/users/${org}/create_${this.sourceValue}_account`,
                type: 'POST',
                dataType: 'json',
                data: stringify({ label: label, account_type: acc }),
                success: function (res) {
                    if (res.error) {
                        context.showAlert(
                            'Error: ' + res.error.length ? res.error : res.error['Fault']['Error'][0]['Message'],
                            {
                                type: 'error',
                                time: 3000,
                            }
                        );
                    } else if (res.existing_account) {
                        context.showAlert(
                            `That account already exists in ${platform_name}. Select ${res.existing_account} from the dropdown menu and if not found click the refresh icon on the upper right.`,
                            {
                                type: 'info',
                                time: 6000,
                            }
                        );
                    } else {
                        if (context.selectTarget.dataset.controller == 'autocomplete') {
                            // set value and lable for autocomplete dropdown
                            let autocomplete_dropdown = context.application.getControllerForElementAndIdentifier(context.selectTarget, 'autocomplete');
                            autocomplete_dropdown.setValueForNewAccount(res.id, res.label);
                        }
                        else {
                            // add option to all
                            // select the option
                            document.querySelectorAll('.jet-category-item').forEach((jeti) => {
                                let elements = jeti.querySelectorAll('[data-controller="search-select"]');
                                elements.forEach((el) => {
                                    let ss = context.application.getControllerForElementAndIdentifier(el, 'search-select');
                                    ss.addOption(res.id, res.label, ss.element == context.selectTarget);
                                });
                            });
                            let change_tracker_ele = context.element.closest('[data-controller*="change-tracker"]');
                            context.sendEvent(change_tracker_ele, 'change-tracker:markDirty');
                            let form_ele = context.element.closest('[data-controller*="form"]');
                            if (form_ele)
                                context.sendEvent(context.element.querySelector('select'), 'form:check-required-fields');
                        }
                        context.showAlert(`${platform_name} Account ${res.label} created.`, {
                            time: 3000,
                        });
                    }
                },
            });
        }
    }
}
