// application_controller.js
import { stringify } from 'querystring';
import Rails from 'rails-ujs';
import { Controller } from 'stimulus';
import AlertController from './alert_controller';

export default class extends Controller {
    connect(): void {
        this.element[`${this.identifier.replaceAll('-', '_')}_controller`] = this;
    }

    get element(): HTMLElement {
        return super.element as HTMLElement;
    }

    showAlert(message: string, options = undefined): void {
        options = Object.assign({ type: 'success', autoDismiss: true }, options || {});
        let css;
        switch (options.type) {
            case 'info':
                css = 'bg-bkblue border-blue-900';
                break;
            case 'error':
                css = 'bg-red-400 border-red-900';
                break;
            case 'warning':
                css = 'bg-secondary border-primary-300';
                break;
            default:
                css = 'bg-bkgreen border-green-900';
                break;
        }
        const content = `<div data-controller="alert" class="max-w-md w-full shadow-lg rounded px-4 py-3 relative border-l-4 ${css} text-white pointer-events-auto" style="transform:none">
      <div class="p-2">
        <div class="flex items-start">
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm leading-5 font-medium">
              ${message}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button data-action="alert#close" class="inline-flex text-white focus:outline-none focus:text-gray-300 transition ease-in-out duration-150">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>`;
        const container = document.querySelector('#alert');
        container.innerHTML = content;
        if (options.type != 'error' && options.autoDismiss) {
            setTimeout(() => {
                const el = container.querySelector('[data-controller="alert"]');
                if (el) {
                    const a: AlertController = (this.application.getControllerForElementAndIdentifier(
                        el,
                        'alert'
                    ) as unknown) as AlertController;
                    a && a.close();
                }
            }, options.time || 6000);
        }
    }

    shake(element: HTMLElement): void {
        element.classList.add('animate-shake');
        setTimeout(() => {
            element.classList.remove('animate-shake');
        }, 1000);
    }

    sendEvent(element: HTMLElement, eventName: string, options: CustomEventInit = undefined): boolean {
        options = Object.assign({ bubbles: true }, options);
        const event = new CustomEvent(eventName, options);
        return element.dispatchEvent(event);
    }

    sendEventListener(element: HTMLElement, eventName: string, options: CustomEventInit = undefined): void {
        options = Object.assign({ bubbles: true }, options);
        const event = new CustomEvent(eventName, options);
        const ele = element.querySelectorAll(`[data-action*='${eventName}']`);
        for (const el of ele) {
            el.dispatchEvent(event);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    ajax(options: any): any {
        const data = options.data;
        if (typeof data == 'object') {
            options.data = stringify(data);
        }
        return Rails.ajax(options);
    }

    disconnect(): void {
        delete this.element[`${this.identifier.replaceAll('-', '_')}_controller`];
    }
}
