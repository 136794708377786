import ApplicationController from './application_controller';
import { enter, leave } from 'el-transition';
import { useWindowResize } from 'stimulus-use';

export default class extends ApplicationController {
    static targets = ['drawer', 'modal', 'offCanvasDrawer'];

    connect() {
        useWindowResize(this);
    }

    windowResize(e) {
        if (e.width >= 1024) {
            this.close();
        }
    }

    close() {
        leave(this.drawerTarget);
        enter(this.modalTarget);
        leave(this.offCanvasDrawerTarget);
    }

    open() {
        this.offCanvasDrawerTarget.classList.remove('hidden');
        enter(this.drawerTarget);
        enter(this.modalTarget);
    }
}
