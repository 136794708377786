import ApplicationController from '../application_controller';

export default class extends ApplicationController {
    static targets = ['routing'];

    static values = {
        validate: {
            type: Boolean,
            default: false,
        },
    };

    showValidation() {
        this.validateValue = true;
        this.validateRouting();
    }

    connect() {}

    validateRouting() {
        let valid;
        if (!this.validateValue) {
            valid = true;
            return valid;
        }

        let val = this.routingTarget.value;
        this.routingTarget.classList.remove('valid');
        this.routingTarget.classList.remove('invalid');
        valid = true;

        if (val.length != 9) {
            valid = false;
        } else {
            // Now run through each digit and calculate the sum.
            let sum = 0;
            for (let i = 0; i < val.length; i += 3) {
                sum +=
                    parseInt(val.charAt(i), 10) * 3 +
                    parseInt(val.charAt(i + 1), 10) * 7 +
                    parseInt(val.charAt(i + 2), 10);
            }

            valid = sum != 0 && sum % 10 === 0;
        }

        if (valid) {
            this.sendEvent(this.routingTarget, 'input:clear-hint', { bubbles: true });
            if (!this.routingTarget.dataset.noValidStyle) this.routingTarget.classList.add('valid');
        } else {
            this.sendEvent(this.routingTarget, 'input:show-hint', {
                bubbles: true,
                detail: { error: 'Please enter a valid routing number' },
            });
            if (!this.routingTarget.dataset.noValidStyle) this.routingTarget.classList.add('invalid');
        }

        return valid;
    }

    numberCheck(event) {
        let valid = true;
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            valid = false;
        }
        return valid;
    }

    validate(e) {
        if (!this.validateRouting()) {
            this.shake(this.routingTarget);
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();
        }
    }
}
