import ApplicationController from './application_controller';
import accounting from 'accounting';
import Cleave from 'cleave.js';
import { numOr0 } from './helpers/math';

export default class extends ApplicationController {
    static targets = ['debitTotal', 'creditTotal', 'errors', 'submit'];

    initialize() {
        this.element.querySelectorAll('.mask-money').forEach((x) => {
            new Cleave(x, { numeral: true, delimiter: false });
        });
        this.calcTotals(false);
    }

    connect() {}

    calcSub(e) {
        var childName = e.currentTarget.dataset.child;
        if (childName) {
            var children = this.element.querySelectorAll('[data-child="' + childName + '"]');
            var subtotal = Array.from(children)
                .map(function (el) {
                    return parseFloat(el.value.replace(/,/g, ''));
                })
                .reduce((a, b) => numOr0(a) + numOr0(b));
            subtotal = accounting.toFixed(subtotal, 2);
            this.element.querySelector('[data-parent="' + childName + '"]').value = subtotal;
        }

        this.calcTotals(true);
    }

    calcTotals(can_disable) {
        var v;
        for (var value of ['credit', 'debit']) {
            v = Array.from(this.element.querySelectorAll('[data-parent].is-' + value))
                .map(function (el) {
                    return parseFloat(el.value.replace(/,/g, ''));
                })
                .reduce((a, b) => numOr0(a) + numOr0(b));
            v = accounting.toFixed(v, 2);
            this[value + 'TotalTarget'].value = v;
        }
        if (this.creditTotalTarget.value == this.debitTotalTarget.value) {
            this.creditTotalTarget.classList.remove('shadow-red');
            this.debitTotalTarget.classList.remove('shadow-red');
            this.creditTotalTarget.classList.add('shadow-blue');
            this.debitTotalTarget.classList.add('shadow-blue');
            this.errorsTarget.classList.add('hidden');
            if (can_disable) {
                this.submitTarget.removeAttribute('disabled');
                this.submitTarget.classList.remove('form-disabled');
            }
            // $('.save-journal-entry').prop('disabled', false);
            // $('.balance-error').hide();
        } else {
            this.creditTotalTarget.classList.remove('shadow-blue');
            this.debitTotalTarget.classList.remove('shadow-blue');
            this.creditTotalTarget.classList.add('shadow-red');
            this.debitTotalTarget.classList.add('shadow-red');
            this.errorsTarget.classList.remove('hidden');
            this.submitTarget.setAttribute('disabled', true);
            this.submitTarget.classList.add('form-disabled');
        }
    }
}
