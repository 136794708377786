import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['option', 'value', 'options'];

    connect() {
        // to make an option selected by default
        if (this.hasOptionsTarget) {
            this.setOption();
        }
    }

    setDefaultValue(inputEle, val = '') {
        inputEle.value = val;
    }

    enableEditableField(radioEle) {
        let radioField = radioEle.querySelector('[type=radio]');
        let editableField = radioEle.querySelector('[type=text]');
        let nonEditableField = radioEle.querySelector('span.label');

        nonEditableField.classList.add('hidden');
        editableField.classList.remove('hidden');
        editableField.focus();
        editableField.setAttribute('required', 'true');
        this.setDefaultValue(editableField, radioField.value);
    }

    disableEditableField(radioEle) {
        let editableField = radioEle.querySelector('[type=text]');
        let nonEditableField = radioEle.querySelector('span.label');

        nonEditableField.classList.remove('hidden');
        editableField.classList.add('hidden');
        editableField.removeAttribute('required');
    }

    setOption() {
        const editable = (x) => x.dataset.editable === 'true';
        this.optionTargets.forEach((opt) => {
            opt.classList.remove('bg-bkblue-50');
            opt.isValid = true;
            if (editable(opt)) {
                opt.isValid = false;
                this.disableEditableField(opt);
            }
            if (opt.querySelector('[type=radio]').checked.toString() === 'true') {
                opt.classList.add('bg-bkblue-50');
                if (editable(opt)) {
                    this.enableEditableField(opt);
                }
            }
        });
        const form = this.element.closest('form');
        this.sendEvent(form, 'form:check-required-fields');
    }

    setValue(inputEle) {
        const val = inputEle.currentTarget.value;
        inputEle.currentTarget.previousElementSibling.value = val;
        if (inputEle.currentTarget.checkValidity()) {
            inputEle.currentTarget.isValid = true;
        } else {
            inputEle.currentTarget.isValid = false;
        }
        const form = this.element.closest('form');
        this.sendEvent(form, 'form:check-required-fields');
    }
}
