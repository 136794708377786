import ApplicationController from './application_controller';
import { stringify } from 'querystring';

export default class extends ApplicationController {
    static targets = ['input'];

    static values = {
        url: {
            type: String,
            default: '',
        },
        initialValue: {
            type: String,
            default: '',
        },
    };

    connect() {
        if (this.inputTarget.value) {
            this.initialValue = this.inputTarget.value;
        }
    }

    checkValueExists() {
        this.inputTarget.isPending = true;
        var context = this;
        var valid = true;
        if (this.inputTarget.value != this.initialValue)
            this.ajax({
                url: context.urlValue,
                type: 'POST',
                dataType: 'json',
                data: stringify({ value: context.inputTarget.value }),
                success: function (data) {
                    if (data.value_exists) {
                        valid = false;
                        context.sendEvent(context.inputTarget, 'input:show-hint', {
                            bubbles: true,
                            detail: {
                                error: 'This name already exists',
                            },
                        });
                    }
                    const form = context.inputTarget.closest('form');
                    context.sendEvent(form, 'form:check-required-fields');
                },
            });
        return valid;
    }
}
