import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {}

    updateConnectionRow() {
        let appsUrl = new URL(this.element.dataset.connectionRowSrc);
        let proAuthId = this.element.dataset.proAuthId;
        appsUrl.searchParams.set('timestamp', Date.now());

        document.getElementById(`provider_authorization_${proAuthId}`).src = appsUrl;
    }
}