import ApplicationController from './application_controller';
import consumer from 'channels/consumer';

export default class extends ApplicationController {
    static values = {
        entityId: String,
    };

    connect() {
        super.connect();
        if (this.entityIdValue) {
            this.subscription = consumer.subscriptions.create(
                {
                    channel: 'PushNotificationsChannel',
                    id: this.entityIdValue,
                },
                {
                    received: this.showMessage.bind(this),
                }
            );
        }
    }

    showMessage(data) {
        const message = data.message;
        const message_type = data.message_type;
        this.showAlert(message, { type: message_type });
    }

    disconnect() {
        this.subscription && this.subscription.unsubscribe();
        super.disconnect();
    }
}
