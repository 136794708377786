import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['checkboxCollection', 'checkbox'];

  verifyIfChecked() {
    this.checkboxCollectionTargets.forEach((ele) => {
      var isValid = ele.querySelectorAll("input[type='checkbox']:checked").length > 0;

      if(!isValid) {
        this.showAlert(ele);
      } else {
        this.hideAlert(ele);
      }
    });

    this.sendEvent(this.element, 'form:check-required-fields')
  }

  checkRequiredFields() {
    let ele = event.target;
    let checkboxCollection = ele.closest('.check-box-collection');
    var isValid = checkboxCollection.querySelectorAll("input[type='checkbox']:checked").length > 0;
    if(!isValid) {
      this.showAlert(checkboxCollection);
    } else {
      this.sendEvent(checkboxCollection, 'input:clear-hint')
    }
    this.sendEvent(this.element, 'form:check-required-fields');
  }

  showAlert(ele) {
    this.sendEvent(ele, 'input:show-hint', {
      bubbles: true,
      detail: {
        error: `Select atleast one`,
      },
    });
  }

  hideAlert(ele) {
    this.sendEvent(ele, 'input:clear-hint')
  }
}
