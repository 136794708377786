import { stringify } from 'querystring';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static values = {
        message: {
            type: String,
            default: 'successful',
        },
        url: {
            type: String,
            default: '',
        },
        post: {
            type: Object,
            default: {},
        },
        frameToRefresh: String,
    };

    declare urlValue: string;
    declare postValue;
    declare messageValue: string;
    declare frameToRefreshValue: string;

    get element(): HTMLInputElement {
        return super.element as HTMLInputElement;
    }

    connect() {
        super.connect();
    }

    autosubmit() {
        const obj = this.postValue;
        const element = this.element;
        obj[element.name] = element.checked ? 1 : 0;
        this.ajax({
            url: this.urlValue,
            type: 'POST',
            dataType: 'json',
            data: stringify(obj),
            success: () => {
                if (this.messageValue) {
                    this.showAlert(this.messageValue, { type: 'success' });
                }
                if (this.frameToRefreshValue) {
                    const element = document.querySelector(`#${this.frameToRefreshValue}_src`) as HTMLElement;
                    element && element.click();
                }
            },
        });
    }
}
