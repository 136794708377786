import { Modal } from 'tailwindcss-stimulus-components';
export default class extends Modal {
    close(e) {
        if (e && e.target && e.target.dataset.preventDefaultClosing) {
            let prev_closing = this.preventDefaultActionClosing;
            this.preventDefaultActionClosing = e.target.dataset.preventDefaultClosing == 'true';
            super.close(e);
            this.preventDefaultActionClosing = prev_closing;
        } else {
            super.close(e);
        }
    }
}
