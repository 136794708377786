import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = [
        'password',
        'confirm',
        'minLengthDisplay',
        'lowerCaseDisplay',
        'upperCaseDisplay',
        'specialCharDisplay',
        'digitDisplay',
    ];

    static values = {
        noErrorMinlength: {
            type: Number,
            default: 0,
        },
    };

    connect() {
        this.config = {
            minLength: 8,
            minUpperCase: 1,
            minLowerCase: 1,
            minSpecial: 1,
            minDigit: 1,
        };
    }

    forceValidatePass() {
        this.noErrorMinlengthValue = 0;
        this.validatePass();
    }

    validatePass() {
        var value = this.passwordTarget.value;

        if (!value) {
            return;
        }

        if (value.length < this.noErrorMinlengthValue) {
            let form = this.passwordTarget.closest('form');
            this.sendEvent(this.passwordTarget, 'input:set-control-valid', {
                bubbles: true,
                detail: { valid: false },
            });
            this.sendEvent(form, 'form:check-required-fields');
            return;
        }

        var lowerRegex = /[a-z]/g;
        var upperReg = /[A-Z]/g;
        var specialChars = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
        var digit = /[0-9]/g;

        var valid = true;
        let error = '';
        if (this.hasMinLengthDisplayTarget) this.minLengthDisplayTarget.classList.add('hidden');
        if (this.hasUpperCaseDisplayTarget) this.upperCaseDisplayTarget.classList.add('hidden');
        if (this.hasLowerCaseDisplayTarget) this.lowerCaseDisplayTarget.classList.add('hidden');
        if (this.hasSpecialCharDisplayTarget) this.specialCharDisplayTarget.classList.add('hidden');
        if (this.hasDigitDisplayTarget) this.digitDisplayTarget.classList.add('hidden');
        if (value.length >= 1) {
            this.sendEvent(this.passwordTarget, 'input:clear-hint', {
                bubbles: true,
            });
            if (this.config.minLength && value.length < this.config.minLength) {
                if (this.hasMinLengthDisplayTarget) {
                    this.minLengthDisplayTarget.classList.remove('hidden');
                }
                error = window.locale['min_password_length'];
                valid = false;
            }
            if (this.config.minUpperCase && this.getRegCount(value, upperReg) < this.config.minUpperCase) {
                if (this.hasUpperCaseDisplayTarget) {
                    this.upperCaseDisplayTarget.classList.remove('hidden');
                }
                error = window.locale['min_uppercase'];
                valid = false;
            }
            if (this.config.minLowerCase && this.getRegCount(value, lowerRegex) < this.config.minLowerCase) {
                if (this.hasLowerCaseDisplayTarget) {
                    this.lowerCaseDisplayTarget.classList.remove('hidden');
                }
                error = window.locale['min_lowercase'];
                valid = false;
            }
            if (this.config.minSpecial && this.getRegCount(value, specialChars) < this.config.minSpecial) {
                if (this.hasSpecialCharDisplayTarget) {
                    this.specialCharDisplayTarget.classList.remove('hidden');
                }
                error = window.locale['min_special_char'];
                valid = false;
            }
            if (this.config.minDigit && this.getRegCount(value, digit) < this.config.minDigit) {
                if (this.hasDigitDisplayTarget) {
                    this.digitDisplayTarget.classList.remove('hidden');
                }
                error = window.locale['min_digits'];
                valid = false;
            }
            if (valid) {
                this.sendEvent(this.passwordTarget, 'input:show-hint', {
                    bubbles: true,
                    detail: { success: window.locale['valid_password_hint'] },
                });
            } else {
                this.sendEvent(this.passwordTarget, 'input:show-hint', {
                    bubbles: true,
                    detail: { error: error },
                });
            }
        } else {
            valid = false;
        }

        if (valid) {
            this.passwordTarget.classList.remove('invalid');
            this.passwordTarget.classList.add('valid');
        } else {
            this.passwordTarget.classList.remove('valid');
            this.passwordTarget.classList.add('invalid');
        }

        if (this.hasConfirmTarget && this.confirmTarget.value.length > 0) {
            this.validateConfirmPass();
        }

        return valid;
    }

    getRegCount(val, reg) {
        let res = 0;
        let iter = val.matchAll(reg);
        while (!iter.next().done) res++;
        return res;
    }

    validateConfirmPass() {
        let valid = this.confirmTarget.value == this.passwordTarget.value;

        if (valid && this.confirmTarget.value.length > 0) {
            this.sendEvent(this.confirmTarget, 'input:clear-hint');
            this.confirmTarget.classList.add('valid');
            this.confirmTarget.classList.remove('invalid');
        } else if (this.confirmTarget.value.length == 0) {
            this.confirmTarget.classList.add('invalid');
            this.confirmTarget.classList.remove('valid');
        } else {
            this.sendEvent(this.confirmTarget, 'input:show-hint', {
                bubbles: true,
                detail: { error: window.locale['passwords_must_match'] },
            });
            this.confirmTarget.classList.add('invalid');
            this.confirmTarget.classList.remove('valid');
        }

        let form = this.passwordTarget.closest('form');
        this.sendEvent(form, 'form:check-required-fields');

        return valid;
    }

    validate(e) {
        let passValid = this.validatePass();
        let confirmPassValid = this.hasConfirmTarget ? this.validateConfirmPass() : true;
        if (!passValid || !confirmPassValid) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            if (!passValid) {
                this.shake(this.passwordTarget);
            }
            if (!confirmPassValid) {
                this.shake(this.confirmTarget);
            }
        }
    }
}
