import ApplicationController from './application_controller';

export default class extends ApplicationController {
   static values = 
   {
    submitUrl: String
   }

   submit(event){
    let companyId = event.target.dataset.companyId;
    this.ajax({
      url: this.submitUrlValue + `?company_id=${companyId}`,
      type: 'POST',
      dataType: 'json',
      success: (data)=>{
        if(data.success){
          window.location.href = data.redirect_url;
        }else{
          this.showAlert(data.error_msg, { type: 'error' });
        }
      }
    })
   }
}