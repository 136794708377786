import ApplicationController from "./application_controller";
import Chart from 'chart.js/auto';

export default class extends ApplicationController {
    static targets = ["chart", "currency", "href"];

    connect() {
        let chart = this.chartTarget;
        new Chart(chart.getContext("2d"), {
            type: chart.dataset.chartType,
            data: {
                labels: JSON.parse(chart.dataset.labels),
                datasets: [{
                    label: 'Gross Sales',
                    data: JSON.parse(chart.dataset.summary_values),
                    tension: 0.1,
                    borderColor: 'rgb(0 155 233)',
                    backgroundColor: 'rgb(0 155 233 / 0.1)',
                    fill: true
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 3,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        display: false,
                        grid: {
                            display: false
                        },
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });
    }

    changeCurrency() {
        let currencyTarget = this.currencyTarget;
        let currency = currencyTarget.value;
        let url = `${currencyTarget.dataset.href}?currency=${currency}`;
        this.hrefTarget.href = url;
        this.hrefTarget.click();
    }
}
