import search_controller from './search_controller';

export default class extends search_controller {
    static targets = ['search', 'noResults', 'tagList', 'tagDisplay', 'visible'];

    connect() {
        this.visibleTarget.value = false;
        if (this.searchTarget.value) {
            super.search(this.searchTarget.value);
        } else {
            var selTag = this.element.querySelector('[data-search-tag]:checked');
            this.applyTagFilter(selTag.getAttribute('data-search-tag'));
        }
    }

    userInput() {
        if (this.searchTarget.value) {
            super.search(this.searchTarget.value);
        } else {
            var selTag = this.element.querySelector('[data-search-tag]:checked');
            this.applyTagFilter(selTag.getAttribute('data-search-tag'));
        }
    }

    filter(e) {
        this.tagListTarget.classList.add('hidden');
        this.visibleTarget.value = false;
        var selTag = e.currentTarget.getAttribute('data-search-tag');
        this.applyTagFilter(selTag);
    }

    catClick() {
        if (this.visibleTarget.value) {
            this.tagListTarget.classList.add('hidden');
            this.visibleTarget.value = false;
        } else {
            this.visibleTarget.value = true;
            this.tagListTarget.classList.remove('hidden');
        }
    }

    viewProvider(e) {
        e.preventDefault();
        var currentLink = e.currentTarget.href;
        currentLink += currentLink.indexOf('?') === -1 ? '?' : '&';
        currentLink += `cat=${this.element.querySelector('[data-search-tag]:checked').getAttribute('data-search-tag')}`;
        var turboLink = document.querySelector('#connections-view');
        turboLink.src = currentLink;
    }

    applyTagFilter(selTag) {
        this.tagDisplayTarget.innerText = selTag;
        this.searchTarget.value = '';
        super.search('');
        var taggedItems = this.element.querySelectorAll('[data-tags]');
        taggedItems.forEach((x) => {
            x.classList.add('hidden');
            if (x.getAttribute('data-tags').length > 0 && (x.getAttribute('data-tags') || '').indexOf(selTag) > -1) {
                x.classList.remove('hidden');
            }
        });
    }
}
