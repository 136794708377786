import ApplicationController from './application_controller';
import q from 'querystring';

export default class extends ApplicationController {
    static targets = ['instance'];

    static values = {
        showValidation: {
            type: Boolean,
            default: true,
        },
        checkEmailExists: {
            type: Boolean,
            default: false,
        },
    };

    showValidation() {
        this.showValidationValue = true;
        this.validateEmail();
    }

    connect() {
        super.connect();
        this.instance = this.element;
        if (this.hasInstanceTarget) {
            this.instance = this.instanceTarget;
        }
    }

    validateEmail() {
        this.instance.isPending = true;
        let form = this.instance.closest('form');
        let val = this.instance.value;
        let valid = true;
        // eslint-disable-next-line no-useless-escape
        var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (val && !filter.test(val)) valid = false;
        if (this.showValidationValue === false && !valid) {
            this.sendEvent(this.instance, 'input:set-control-valid', {
                bubbles: true,
                detail: { valid: false },
            });
            this.sendEvent(form, 'form:check-required-fields');
            return;
        }

        if (val) {
            if (valid) {
                if (this.checkEmailExistsValue) {
                    var context = this;
                    this.ajax({
                        url: '/find_user',
                        type: 'POST',
                        dataType: 'json',
                        data: q.stringify({ email: val }),
                        success: function (data) {
                            if (data.email_avail) {
                                context.sendEvent(context.instance, 'input:show-hint', {
                                    bubbles: true,
                                    detail: { error: window.locale['email_exists'].replace('{email}', val) },
                                });
                            } else {
                                context.sendEvent(context.instance, 'input:clear-hint', { bubbles: true });
                            }
                            context.sendEvent(form, 'form:check-required-fields');
                        },
                    });
                } else {
                    this.sendEvent(this.instance, 'input:clear-hint', { bubbles: true });
                }
            } else {
                this.sendEvent(this.instance, '');
                this.sendEvent(this.instance, 'input:show-hint', {
                    bubbles: true,
                    detail: { error: 'Please enter a valid email' },
                });
            }
        }
        return valid;
    }

    validate(e) {
        if (!this.validateEmail()) {
            this.shake(this.instance);
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();
        }
    }
}
