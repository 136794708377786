import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['select', 'value'];

    connect() {
        super.connect();
        this.change();
    }

    change() {
        if (this.selectTarget.selectedOptions[0]) {
            this.valueTarget.value = this.selectTarget.selectedOptions[0].innerText;
        }
    }
}
