import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static values = {
        selectedOption: {
            type: String,
            default: '',
        },
    };

    connect() {
        for (let option of this.element.options) {
            if (option.value == this.selectedOptionValue) {
                option.setAttribute('selected', '');
                break;
            }
        }
    }
}
