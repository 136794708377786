import application_controller from './application_controller';

export default class extends application_controller {
    static targets = ['email'];

    emailExistsUpdate(e) {
        if (e.detail.email_avail) {
            this.sendEvent(this.emailTarget, 'input:show-hint', {
                detail: { error: window.locale['email_exists'].replace('{email}', e.detail.email) },
            });
            // @swetha please check this
            // eslint-disable-next-line no-undef
            this.sendEvent(form, 'form:check-required-fields');
        }
    }
}
