import ApplicationController from '../application_controller';

export default class extends ApplicationController {
    static targets = ['tab', 'cta'];

    connect() {
        super.connect();
        this.setCTA();
    }

    setCTA() {
        if (this.hasTabTarget) {
            const activeTab = this.tabTargets.filter((x) => x.classList.contains('active'))[0].dataset.name;

            this.ctaTargets.forEach((cta) => {
                const activeForTabs = cta.dataset.activeFor ? cta.dataset.activeFor.split(' ') : [];
                if (activeForTabs.length > 0) {
                    if (activeForTabs.indexOf(activeTab) == -1) {
                        cta.classList.add('!hidden');
                    } else {
                        cta.classList.remove('!hidden');
                    }
                }
            });
        }
    }
}
