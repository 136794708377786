import { Alert } from 'tailwindcss-stimulus-components';

export default class AlertController extends Alert {
    show(): void {
        this.element.setAttribute('style', 'transition: 1s; transform:translateY(0, 0);');
    }

    hide(): void {
        this.element.setAttribute('style', 'transition: 1s; transform:translateY(-7.5rem);');
    }
}
