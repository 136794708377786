import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['defaultCost', 'variantCost', 'defaultCostButton', 'defaultEffectiveDate', 'variantEffectiveDate', 'defaultEffectiveDateButton'];

    connect() { }

    showMessage() {
        var message = 'Performing action';
        this.showAlert(`${message}`, { type: 'success' });
    }

    enableOrDisableCostApplyAllButton() {
        if(this.defaultCostTarget.value == '') {
            this.defaultCostButtonTarget.classList.add('form-disabled');
            this.defaultCostButtonTarget.setAttribute("disabled", "true");
        } else {
            this.defaultCostButtonTarget.classList.remove('form-disabled');
            this.defaultCostButtonTarget.removeAttribute("disabled");
        }
    }

    enableOrDisableEffectiveDateApplyAllButton() {
        if(this.defaultEffectiveDateTarget.value == '') {
          this.defaultEffectiveDateButtonTarget.classList.add('form-disabled');
          this.defaultEffectiveDateButtonTarget.setAttribute("disabled", "true");
        } else {
            this.defaultEffectiveDateButtonTarget.classList.remove('form-disabled');
            this.defaultEffectiveDateButtonTarget.removeAttribute("disabled");
        }
    }

    applyDefaultCostToVariants(e) {
        e.preventDefault();
        if(this.defaultCostTarget.value < 0) {
            this.showAlert('Cost cannot be negative', { type: 'error' });
            return;
        }
        this.variantCostTargets.forEach((element) => {          
            element.value = this.defaultCostTarget.value;
        });
    }

    applyDefaultEffectiveDateToVariants(e) {
        e.preventDefault();
        if(this.defaultEffectiveDateTarget.value == '') {
            this.showAlert('Please select date', { type: 'error' });
            return;
        }
        this.variantEffectiveDateTargets.forEach((element) => {          
            element.value = this.defaultEffectiveDateTarget.value;
        });
    }
}