import { leave } from 'el-transition';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['template', 'container', 'loading', 'loadListener'];

    static values = {
        url: String,
        isFetched: {
            type: Boolean,
            default: false,
        },
    };

    load() {
        if (!this.isFetchedValue) {
            var context = this;
            if (this.urlValue) {
                this.isFetchedValue = true;
                this.ajax({
                    url: this.urlValue,
                    type: 'GET',
                    dataType: 'json',
                    success: function (response) {
                        const template = context.templateTarget.innerHTML.replaceAll('tsafe-', '');

                        response.data.forEach((item) => {
                            var element = template;
                            Object.keys(item).forEach((key) => (element = element.replaceAll(`[[${key}]]`, item[key])));
                            context.containerTarget.insertAdjacentHTML('beforeend', element);
                        });

                        context.hasLoadingTarget && leave(context.loadingTarget);

                        const event = new CustomEvent('lazy-load:updated');
                        context.element.dispatchEvent(event);
                        context.hasLoadListenerTarget &&
                            context.loadListenerTargets.forEach((x) => {
                                x.dispatchEvent(event);
                            });
                    },
                });
            } else {
                console.error('No URL supplied');
            }
        }
    }
}
