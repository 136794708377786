import { Grid, Popup, Tooltip } from '@components';
import FrameElement from '@hotwired/turbo-rails';
import { PageOptions, SortOptions } from '@components/shared';
import { createElement, stringifyParams } from '@utils';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = [
        'gridContainer',
        'runDateRange',
        'runDateRangeButton',
        'search'
    ];

    declare gridContainerTarget: HTMLElement;
    declare searchTarget: HTMLInputElement;
    declare runDateRangeButtonTarget: HTMLElement;
    declare runDateRangeTarget: FrameElement;
    declare hasRunDateRangeButtonTarget: boolean;

    static values = {
        confirmActionFormId: String,
        url: {
            type: String,
            default: '',
        },
    };

    declare urlValue: string;

    private grid: Grid<BalanceTransaction>;
    private parmsObj: ParamsObj = {};
    private disabled: boolean;

    connect(): void {

        this.parmsObj.page = {
            size: 30,
            number: 1
        };

        this.createGrid();
        this.reload();
    }

    private createGrid() {
        this.grid = new Grid<BalanceTransaction>({
            key: 'id',
            allowSelection: true,
            showEmptyState: true,
            noRecordsTemplate: this.noRecordsTemplate,
            container: this.gridContainerTarget,
            columns: [
                {
                    type: 'checkbox',
                },
                {
                    headerTitle: 'State',
                    field: 'state',
                    width: '128px',
                    sortable: true,
                    cellTemplate: (bal_transaction) => {
                        let classes = 'bg-red-100 text-red-800';
                        switch (bal_transaction.attributes.state) {
                            case 'posted':
                                classes = 'bg-bkgreen';
                                break;
                            case 'draft':
                                classes = 'bg-secondary';
                                break;
                            case 'delete_failed':
                                classes = 'bg-secondary';
                                break;
                            case 'excluded':
                                classes = 'bg-gray-100 text-gray-800';
                                break;
                            case 'deleted':
                                classes = 'bg-bkred-300';
                                break;
                        }
                        return `
                            <span class="cursor-pointer overflow-auto inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white ${classes} uppercase"> 
                                ${bal_transaction.attributes.state.replace("_", " ")}
                            </span>
                        `;
                    },
                    onCellClicked: (bal_transaction: BalanceTransaction) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any).openPopup(bal_transaction.links.self);
                    },
                },
                {
                    headerTitle: 'Date',
                    width: '128px',
                    field: 'sat_date',
                    sortable: true,
                    cellTemplate: (bal_transaction) => {
                        return `${bal_transaction.attributes.formatted_sat_date}`;
                    }
                },
                {
                    headerTitle: 'Amount',
                    width: '120px',
                    field: 'sat_amount',
                    sortable: true,
                    cellTemplate: (bal_transaction) => {
                        return `${bal_transaction.attributes.formatted_sat_amount}`;
                    }
                },
                {
                    headerTitle: 'Account',
                    field: 'sat_account1',
                    width: '200px',
                    sortable: false,
                    cellTemplate: (bal_transaction) => {
                        return `
                            <span class="cursor-pointer">
                                ${bal_transaction.attributes.sat_account1}
                            </span>
                        `;
                    },
                    onCellClicked: (bal_transaction: BalanceTransaction) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any).openPopup(bal_transaction.links.self);
                    },
                },
                {
                    headerTitle: 'Transaction Summary',
                    field: 'sat_summary',
                    width: '350px',
                    sortable: false,
                    cellTemplate: (bal_transaction) => {
                        return `
                            <span class="cursor-pointer">
                                ${bal_transaction.attributes.sat_summary}
                            </span>
                        `;
                    },
                    onCellClicked: (bal_transaction: BalanceTransaction) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any).openPopup(bal_transaction.links.self);
                    },
                },
                {
                    headerTitle: '',
                    customRowClasses: 'justify-center',
                    cellTemplate: (bal_transaction) => {
                        let template: string;

                        const btnTemplate = '<button class="p-1 rounded border border-gray-400 focus:outline-none ">';
                        let status: string = '';

                        const infoSVG = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="#209cee">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                </svg>`;
                        const alertSVG = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="#ff3860">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>`;

                        template = btnTemplate;
                        switch (bal_transaction.attributes.state) {
                            case 'draft':
                                template = template + infoSVG;
                                status = 'Map accounts to post this transaction.';
                                break;
                            case 'posting':
                                template = template + infoSVG;
                                status = 'Please wait while we post this transaction.';
                                break;
                            case 'excluded':
                                template = template + infoSVG;
                                status = 'This transcation has been excluded. To post this transaction restore and post.';
                                break;
                            case 'deleted':
                                template = template + infoSVG;
                                status = 'This transaction has been deleted from your accouting platform.';
                                break;
                            case 'post_failed':
                            case 'posting_notes_failed':
                            case 'delete_failed':
                            case 'update_failed':
                                template = template + alertSVG;
                                status = bal_transaction.attributes.error_message;
                                break;
                            case 'posted':
                                template = `<div class="cursor-pointer hover:text-bkblue-hover focus:text-bkblue">
                                    View in ${bal_transaction.attributes.ta_name}
                                </div>`;
                                status = 'Posted in your accounting platform. Click to view.';
                                break;
                        }

                        const element = createElement(template);
                        if (status) {
                            new Tooltip({
                                element,
                                content: status,
                                class: 'whitespace-normal',
                            });
                        }
                        element.querySelectorAll('[bk-tooltip]').forEach((element: HTMLElement) => {
                            new Tooltip({
                                element,
                                content: element.getAttribute('bk-tooltip'),
                                class: 'whitespace-normal',
                            });
                        });

                        return element;
                    },
                    onCellClicked: (bal_transaction: BalanceTransaction) => {
                        if (bal_transaction.attributes.state == 'posted') {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (window as any).open(bal_transaction.attributes.tat_url, '_blank');
                        }
                    },
                },
                {
                    type: 'actions',
                    actions: [
                        {
                            title: 'View in Source App',
                            tooltipMessage: 'View in Source App',
                            onClick: (bal_transaction: BalanceTransaction) => {
                                window.open(bal_transaction.attributes.sat_url, '_blank');
                            },
                            disabled: (bal_transaction: BalanceTransaction) => {
                                return (bal_transaction.attributes.sat_url == null);
                            }
                        },
                        {
                            title: 'View in Accounting Platform',
                            tooltipMessage: 'View in Accounting Platform',
                            onClick: (bal_transaction: BalanceTransaction) => {
                                window.open(bal_transaction.attributes.tat_url, '_blank');
                            },
                            disabled: (bal_transaction: BalanceTransaction) => {
                                return (bal_transaction.attributes.tat_url == null);
                            },
                        },
                        {
                            title: 'Delete from Accounting Platform',
                            tooltipMessage: 'Delete from Accounting Platform',
                            onClick: (bal_transaction: BalanceTransaction) => {
                                const res = confirm('Delete from Accounting Platform');
                                if (res) {
                                    this.ajax({
                                        url: bal_transaction.links.perform_action_url,
                                        type: 'POST',
                                        dataType: JSON,
                                        data: stringifyParams({ action_name: 'delete_from_accounting_platform' }),
                                        success: (response) => {
                                            if (response.error) {
                                                this.showAlert(response.error, { type: 'error' });
                                            } else if (response.success) {
                                                this.showAlert(response.success, { type: 'success' });
                                            }
                                            this.reload();
                                        },
                                    });
                                }
                            },
                            disabled: (bal_transaction: BalanceTransaction) => {
                                return (bal_transaction.attributes.tat_id == null || bal_transaction.attributes.tat_id == '' || bal_transaction.attributes.state == 'deleted');
                            },
                        }
                    ],
                },
            ],
            pageOptions: {
                number: this.parmsObj.page.number,
                size: this.parmsObj.page.size,
            },
            onSort: (sort: SortOptions) => this.sort(sort),
            onPageChange: (page: PageOptions) => this.page(page),
        });
    }

    private noRecordsTemplate = () => {
        let message = 'No records to show.';
        return `<div class="h-96 flex justify-center items-center text-gray-600 px-4">
        <div class="text-center"> ${message} </div>`;
    };

    previous(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) - 1;
        this.reload();
    }

    next(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) + 1;
        this.reload();
    }

    disableButtons(): void {
        this.disabled = true;
    }

    enableButtons(): void {
        this.disabled = false;
    }

    reload(): void {
        let url = this.urlValue || '';
        let currentPageSize = this.parmsObj.page.size
        const query = stringifyParams(this.parmsObj);
        if (query.length) {
            url = url + '?' + query;
        }
        this.ajax({
            url,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                this.grid.pageOptions.size = currentPageSize;
                this.grid.pageOptions.number = data.meta.pagination.current;
                this.grid.totalResultsCount = data.meta.pagination.records;
                this.grid.data = data.data;
            },
        });
    }

    pageSize(e: Event): void {
        this.parmsObj.page.number = 1;
        e.preventDefault();
        this.reload();
    }

    private sort(sort: SortOptions): void {
        this.parmsObj.sort = sort.direction == 'asc' ? sort.column : '-' + sort.column
        this.reload();
    }

    private page(page: PageOptions): void {
        this.parmsObj.page = {
            number: page.number,
            size: page.size
        };
        this.reload();
    }

    resetSelected(): void {
        this.grid.resetSelection();
    }

    disconnect() {
        super.disconnect();
    }

    filter(e: CustomEvent): void {
        const filter_value = e.detail.value;
        this.parmsObj.page.number = 1;

        // Reset search filters
        delete this.parmsObj.filter;
        this.searchTarget.value = '';

        if (filter_value == 'review') {
            this.parmsObj.filter = { 'state_eq': 'draft' };
        }
        else if (filter_value == 'posted') {
            this.parmsObj.filter = { 'state_eq': 'posted' };
        }
        else if (filter_value == 'excluded') {
            this.parmsObj.filter = { 'state_eq': 'excluded' };
        }
        this.resetSelected();
        this.reload();
    }

    search(val: string): void {
        val = val.trim();
        const currentSearch = this.parmsObj.filter?.['state_or_sat_transaction_type_or_sat_memo_or_sat_description_i_cont_any']?.replace(/\s+/g, ',')
        if (currentSearch == val || (!currentSearch && !val)) {
            return;
        }
        this.parmsObj.page.number = 1;
        this.parmsObj.filter ||= {}
        this.parmsObj.filter['state_or_sat_transaction_type_or_sat_memo_or_sat_description_i_cont_any'] = val.replace(/\s+/g, ',');
        this.resetSelected();
        this.reload();
    }

    searchChange(): void {
        clearTimeout(+this.data.get('searchTimeout'));
        this.resetSelected();

        this.data.set(
            'searchTimeout',
            setTimeout(() => {
                this.search(this.searchTarget.value);
            }, 500).toString()
        );
    }

    runDateRange(e: Event): void {
        const url = (e.currentTarget as HTMLElement).dataset.href;
        this.runDateRangeTarget.src = url;
        this.runDateRangeTarget.classList.remove('hidden');
    }

    closeDateRange(e: Event): void {
        e.preventDefault();
        this.runDateRangeTarget.classList.add('hidden');
    }
}

interface ParamsPageObj {
    number?: number
    size?: number
}

interface ParamsObj {
    search?: string;
    filter?: object;
    status?: string;
    sort?: string;
    page?: ParamsPageObj;
}
interface BalanceTransactionAttributes {
    // all the required attributes should be defined in btgrid_serializer.rb
    id: number;
    state: string;
    formatted_sat_date: string;
    sat_account1: string;
    sat_summary: string;
    formatted_sat_amount: string;
    sat_url: string;
    tat_url: string;
    tat_id: string;
    error_message: string;
    ta_name: string;
}

interface LinkAttributes {
    // all the required attributes should be defined in btgrid_serializer.rb
    self: string;
    perform_action_url: string;
}

interface BalanceTransaction {
    // all the required attributes should be defined in btgrid_serializer.rb
    attributes: BalanceTransactionAttributes;
    links: LinkAttributes;
}
