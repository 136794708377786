import ApplicationController from './application_controller';
import intlTelInput from 'intl-tel-input';
import Rails from 'rails-ujs';
import { useIntersection } from 'stimulus-use';
import { AsYouType } from 'libphonenumber-js';

export default class extends ApplicationController {
    static targets = ['input', 'ext'];

    connect() {
        useIntersection(this);
    }

    initialize() {
        let selectedCountry, res, iso2;

        selectedCountry = this.extTarget.value;
        if (selectedCountry) {
            res = selectedCountry.replace(/=>/gi, ':');
            iso2 = JSON.parse(res)['iso2'];
        } else {
            iso2 = 'auto';
        }
        var config = {
            formatOnInit: true,
            separateDialCode: true,
            initialCountry: iso2,
            preferredCountries: ['US', 'CA', 'GB'],
            phoneValidation: true,
            utilsScript: "intl-tel-input/build/js/utils.js",
            geoIpLookup: function (success) {
                Rails.ajax({
                    url: 'https://ipinfo.io',
                    type: 'GET',
                    dataType: 'jsonp',
                    success: function (resp) {
                        var countryCode = resp && resp.country ? resp.country : 'us';
                        success(countryCode);
                    },
                    error: function () {
                        success('us');
                    },
                });
            },
        };

        if (this.data.get('custom-container')) {
            config.customContainer = this.data.get('custom-container');
        }

        this.inputTarget._iti = intlTelInput(this.inputTarget, config);

        this.adjustPadding();

        this.element.querySelector('.iti__selected-flag').tabIndex = 1;

        this.format();
    }

    appear() {
        this.adjustPadding();
    }

    adjustPadding() {
        const iti = this.inputTarget._iti;
        const selectedFlagWidth = iti.selectedFlag.offsetWidth || iti._getHiddenSelectedFlagWidth();
        iti.telInput.style.paddingLeft = ''.concat(selectedFlagWidth + 6, 'px');
    }

    onCountryChange() {
        let iti = this.inputTarget._iti;
        let countryData = iti.getSelectedCountryData();
        this.extTarget.value = JSON.stringify(this.extData(countryData));
        let form = this.element.closest('form');
        this.sendEvent(form, 'form:check-required-fields');
    }

    checkValidation() {
        let isValid = false;
        let iti = this.inputTarget._iti;
        const errorCode = iti.getValidationError();

        if(errorCode !== 0) {
            isValid = false
            this.sendEvent(this.inputTarget, 'input:show-hint', {
                bubbles: true,
                detail: { error: 'Please enter a valid phone number' },
            });
        } else {
            isValid = true;
            this.sendEvent(this.inputTarget, 'input:clear-hint');
        }

        return isValid;
    }

    extData(countryData) {
        var ext_hash_data = {
            iso2: countryData.iso2,
            name: countryData.name,
            dialCode: countryData.dialCode,
        };
        return ext_hash_data;
    }

    format(e) {
        let value = this.inputTarget.value;
        let formatted = new AsYouType('US').input(value);
        if (e && e.inputType == 'deleteContentBackward' && formatted.length > value.length) {
            let newVal = value.split('');
            newVal.pop();
            formatted = new AsYouType('US').input(newVal.join(''));
        }
        this.inputTarget.value = formatted;
    }
}
