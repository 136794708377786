import ApplicationController from './application_controller';
import { stringify } from 'querystring';

export default class extends ApplicationController {
    static targets = ['email'];

    static values = {
        url: {
            type: String,
            default: '/find_user',
        },
    };

    checkEmail() {
        var email = this.emailTarget.value;
        var context = this;
        this.ajax({
            url: this.urlValue,
            type: 'POST',
            dataType: 'json',
            data: stringify({ email: email }),
            success: function (data) {
                context.sendEvent(context.emailTarget, 'email-exists:update', {
                    detail: data,
                });
            },
        });
    }
}
