import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['source', 'destination'];
    static values = {
        userTriedSubmit: {
            type: Boolean,
            default: false,
        },
    };

    connect() {
        this.element.addEventListener('form:submit', () => {
            this.userTriedSubmitValue = true;
        });
    }

    validate(e) {
        if (this.userTriedSubmitValue) {
            if (this.sourceTarget.value != this.destinationTarget.value) {
                e.preventDefault();
                this.sendEvent(this.destinationTarget, 'input:show-hint', {
                    bubbles: true,
                    detail: {
                        error: 'Account numbers do not match',
                    },
                });
                this.sendEvent(this.sourceTarget, 'input:show-hint', {
                    bubbles: true,
                    detail: {
                        error: 'Account numbers do not match',
                    },
                });
                return false;
            } else {
                this.sendEvent(this.sourceTarget, 'input:clear-hint');
                this.sendEvent(this.destinationTarget, 'input:clear-hint');
            }
        }
        return true;
    }
}
