import { Controller } from 'stimulus';

export default class extends Controller {
    click(e) {
        e.preventDefault();
        var link = this.element.getAttribute('href');
        var width = this.element.getAttribute('data-width');
        var height = this.element.getAttribute('data-height');

        var sep = link.indexOf('?') !== -1 ? '&' : '?',
            url = link + sep + 'popup=true',
            left = (screen.width - width) / 2 - 16,
            top = (screen.height - height) / 2 - 50,
            windowFeatures = 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top;
        window.open(url, 'authPopup', windowFeatures);
        return true;
    }
}
