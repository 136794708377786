import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static values = { url: String, loaderSrc: String };
    static targets = ['refresh', 'loader'];

    connect() {
        super.connect();
        if (this.hasLoaderTarget) {
            this.loaderTarget.style.mask = `url(${this.loaderSrcValue})`;
        }
    }

    removeBanner(e) {
        e.preventDefault();
        let context = this;
        this.ajax({
            url: this.urlValue,
            type: 'POST',
            success: () => {
                context.reloadBanner(null);
            },
        });
    }

    reloadBanner(e) {
        if (e) e.preventDefault();
        if (this.hasRefreshTarget) this.refreshTarget.classList.add('animate-spin');
        document.querySelector('#filter-populate-banner').reload();
    }
}
