import ApplicationController from './application_controller';
export default class extends ApplicationController {
    connect() {
        this.element['controller'] = this;
    }

    setLoadParam() {
        this.data.set('load', true);
    }
}
