import { Controller } from 'stimulus';
import { useHotkeys } from 'stimulus-use';

export default class extends Controller {
    connect() {
        useHotkeys(this, {
            'cmd+k': [this.orgSwitch],
            'ctrl+k': [this.orgSwitch],
        });
    }

    orgSwitch(e) {
        let val = '';
        if (e.currentTarget.id == 'search_field') {
            val = e.currentTarget.value;
            if (!val) {
                return;
            }
            e.currentTarget.value = '';
        }
        let modalController = this.application.getControllerForElementAndIdentifier(
            document.querySelector('#org-switch'),
            'modal'
        );
        this.application
            .getControllerForElementAndIdentifier(document.querySelector('#org-switch'), 'lazy-loader')
            .load();
        let searchEl = modalController.element.querySelector('[data-controller="search-navigate"]');
        if (searchEl) {
            let searchController = this.application.getControllerForElementAndIdentifier(searchEl, 'search-navigate');
            searchController.searchTarget.value = val;
            searchController.search(val);
        }

        modalController.open(e);
        modalController.element.querySelector('input').focus();
        return false;
    }
}
