import ApplicationController from './application_controller';
import { stringify } from 'querystring';

export default class extends ApplicationController {
    static targets = ['fromDate', 'toDate', 'jemId', 'jet', 'importPopup', 'submit'];

    static values = {
        url: {
            type: String,
            default: '/import_data',
        },
    };

    connect() {
        super.connect();
    }

    checkImportRange(e) {
        e.preventDefault();
        var context = this;
        if (this.hasFromDateTarget && this.hasToDateTarget && this.hasJemIdTarget && this.hasJetTarget) {
            let fromDate = this.fromDateTarget.value;
            let toDate = this.toDateTarget.value;
            let jemId = this.jemIdTarget.value;
            let jet = this.jetTarget.value;
            this.ajax({
                url: `${this.urlValue}/import_data`,
                type: 'POST',
                dataType: 'json',
                data: stringify({
                    from: fromDate,
                    to: toDate,
                    jem_id: jemId,
                    journal_entry_template: jet,
                }),
                success: function (data, status, xhr) {
                    if (data.no_of_days && data.show_popup) {
                        context.importPopupTarget.href += `?days=${data.no_of_days}&jem_id=${jemId}&jet=${jet}&from=${fromDate}&to=${toDate}`;
                        context.importPopupTarget.click();
                    } else if (data.stop_submit && !data.show_popup) {
                        context.submitTarget.classList.remove('processing');
                        context.submitTarget.removeAttribute('disabled');
                        if (xhr.getResponseHeader('x-alert'))
                            context.showAlert(xhr.getResponseHeader('x-alert'), {
                                type: xhr.getResponseHeader('x-alert-type'),
                            });
                    } else {
                        context.postDateRange(fromDate, toDate, jemId, jet);
                    }
                    context.sendEvent(context.element, 'grid:closeDateRange');
                },
            });
        }
    }

    postDateRange(fromDate, toDate, jemId, jet) {
        let context = this;
        this.ajax({
            url: `${this.urlValue}/post_date_range`,
            type: 'POST',
            dataType: 'json',
            data: stringify({
                from: fromDate,
                to: toDate,
                jem_id: jemId,
                journal_entry_template: jet,
            }),
            success: function (data, status, xhr) {
                if (xhr.getResponseHeader('x-alert')) {
                    context.showAlert(xhr.getResponseHeader('x-alert'), {
                        type: xhr.getResponseHeader('x-alert-type'),
                    });
                    context.reloadBanner();
                }
            },
        });
    }

    reloadBanner() {
        document.querySelector('#import-banner').reload();
    }
}
