import ApplicationController from './application_controller';

export default class extends ApplicationController {
    connect() {}

    // call the enter and leave functions
    toggle(e) {
        this.element.querySelector('.active')?.classList.remove('active');
        e.currentTarget.classList.add('active');
    }
}
