import moment from 'moment';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['date'];

    connect() {
        super.connect();
    }

    checkDate(e) {
        if (this.dateTarget.value) {
            let val = this.dateTarget.value;
            if (
                moment(val).isAfter(moment().startOf('day').subtract(1, 'seconds')) &&
                moment(val).isBefore(moment().endOf('day'))
            ) {
                var r = confirm(
                    "Running for today's date could result in an incomplete report if your store is still open for business. Are you sure you want to run today's report?"
                );
                if (r != true) {
                    e.preventDefault();
                }
            }
        }
    }
}
