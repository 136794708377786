import { Tabs } from 'tailwindcss-stimulus-components';
export default class extends Tabs {
    static targets = super.targets.concat(['tabs', 'highlight', 'frame']);

    initialize() {
        const el = this.element.querySelector('.active');
        setTimeout(() => {
            this.highlightElement(el);
        }, 100);
        super.initialize();
    }

    loadSource(e) {
        this.frameTargets.forEach(frame => {
            if ((frame.id === e.currentTarget.dataset['tabsFrameId']) && (!frame.src || !frame.src.includes(e.params.url))) {
                frame.src = e.params.url;
            }
        });
    }

    change(e) {
        const activeElement = e.currentTarget;
        this.highlightElement(activeElement);
        super.change(e);
    }

    highlightElement(el) {
        const rect = el.getBoundingClientRect();
        const highlightWidth = rect.right - rect.left;
        this.highlightTarget.style.width = highlightWidth + 'px';
        this.highlightTarget.style.left = rect.left - this.tabsTarget.getBoundingClientRect().left + 'px';
    }
}
