/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Turbo } from '@hotwired/turbo-rails';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import { createRetoolEmbed } from '@tryretool/retool-embed';


// Look for controllers inside app/javascripts/packs/controllers/
if (!window.Turbo) window.Turbo = Turbo;
const application = Application.start();
const context = require.context('../controllers', true, /\.(js|ts)$/);
application.load(definitionsFromContext(context));

// Make it accessible globally
if (!window.createRetoolEmbed) window.createRetoolEmbed = createRetoolEmbed;

// memorize default handler
const defaultErrorHandler = application.handleError.bind(application);

// configure Rollbar to log errors and prepend the default handler
const rollbarErrorHandler = (error, message, detail = {}) => {
    defaultErrorHandler(error, message, detail);
    window.Rollbar && window.Rollbar.error(error, window.location.href);
};

// overwrite the default handler with our new composed handler
application.handleError = rollbarErrorHandler;

import './stylesheets/application';

import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils.js'

// Import style for flatpickr
require('flatpickr/dist/themes/dark.css');

