import ApplicationController from '../application_controller';

export default class extends ApplicationController {
    static targets = ['highlight', 'leftArrow', 'rightArrow', 'scrollView', 'tabs'];

    initialize() {
        const el = this.element.querySelector('.active');
        const context = this;
        this.bindChangeParentArrows = this.changeParentArrows.bind(this);
        setTimeout(function () {
            context.highlightElement(el);
            context.changeParentArrows();
        }, 100);
    }

    connect() {
        this.scrollViewTarget.addEventListener('scroll', this.bindChangeParentArrows);
        window.addEventListener('resize', this.bindChangeParentArrows);
    }

    disconnect() {
        this.scrollViewTarget.removeEventListener('scroll', this.bindChangeParentArrows);
        window.removeEventListener('resize', this.bindChangeParentArrows);
    }

    changeParentArrows() {
        const parent = this.scrollViewTarget;
        const left = this.leftArrowTarget;
        const right = this.rightArrowTarget;
        left.classList.add('hidden');
        right.classList.add('hidden');
        if (parent.scrollWidth > parent.clientWidth) {
            left.classList.remove('hidden');
            right.classList.remove('hidden');
            if (parent.scrollLeft >= parent.scrollWidth - parent.clientWidth - 1) {
                right.classList.add('hidden');
            }
            if (parent.scrollLeft == 0) {
                left.classList.add('hidden');
            }
        }
    }

    scrollLeft() {
        const el = this.scrollViewTarget;
        el.scrollTo(el.scrollLeft - (el.clientWidth * 2) / 3, 0);
    }

    scrollRight() {
        const el = this.scrollViewTarget;
        el.scrollTo(el.scrollLeft + (el.clientWidth * 2) / 3, 0);
    }

    changeTab(e) {
        let changeTab = false;
        changeTab = this.sendEvent(document, 'turbo-tabs:change', { cancelable: true, detail: {} });
        if (changeTab) {
            this.element.querySelector('.active').classList.remove('active');
            const activeElement = e.currentTarget;
            e.currentTarget.classList.add('active');
            this.highlightElement(activeElement);
            this.sendEvent(this.element, 'turbo-tabs:changed');
        } else {
            e.preventDefault();
        }
    }

    highlightElement(el) {
        const rect = el.getBoundingClientRect();
        const highlightWidth = rect.right - rect.left;
        this.highlightTarget.style.width = highlightWidth + 'px';
        this.highlightTarget.style.left = rect.left - this.tabsTarget.getBoundingClientRect().left + 'px';
    }
}
