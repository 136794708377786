import ApplicationController from './application_controller';
import consumer from 'channels/consumer';

export default class extends ApplicationController {
    static targets = ['notificationsCount'];

    connect() {
        super.connect();
        this.refreshCount();
        this.userId = this.element.dataset.userId;
        this.subscription = consumer.subscriptions.create(
            {
                channel: 'NotificationsCountChannel',
                user_id: this.userId
            },
            {
                received: this.refreshCount.bind(this),
            }
        );
    }

    refreshCount() {
        var ctx = this;
        if (this.hasNotificationsCountTarget) {
            this.ajax({
                url: '/notifications/pending_count',
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    if(data.count > 0) {
                        // ctx.notificationsCountTarget.innerText = data.display_count;
                        ctx.notificationsCountTarget.classList.add('notification__badge');
                    } else {
                        // ctx.notificationsCountTarget.innerText = '';
                        ctx.notificationsCountTarget.classList.remove('notification__badge');
                    }
                },
            });
        }
    }

    disconnect() {
        this.subscription && this.subscription.unsubscribe();
        super.disconnect();
    }
}
